import { t as tr } from 'i18next';
import React from 'react';
import { Form, Input, TextArea } from 'semantic-ui-react';

import MultiSelectInput from '../../MultiSelectInput/MultiSelectInput';

import type { Option } from '../../MultiSelectInput/MultiSelectInput';
import type { ReplySalesforceState } from '../ReplySalesforce';
import type { SalesforceFormPayload } from 'src/types/Salesforce';

interface IProps {
  validationErrors: { [key: string]: any };
  payload: SalesforceFormPayload;

  handleSetState: (fields: Partial<ReplySalesforceState['payload']>) => void;
}

const SalesforceEmailForm: React.FC<IProps> = ({ payload, validationErrors, handleSetState }) => {
  const arrayEmailErrorKey = Object.keys(validationErrors).find((key) => key.match(/Emails\[([0-9])*\].value/));

  return (
    <>
      <Form.Field
        required
        error={
          validationErrors.Emails
            ? {
                content: validationErrors.Emails,
                pointing: 'above'
              }
            : arrayEmailErrorKey && {
                content: validationErrors[arrayEmailErrorKey],
                pointing: 'above'
              }
        }
        control={Input}
        label={tr('GENERAL_EMAILS')}
      >
        <MultiSelectInput
          value={payload.Emails || []}
          placeholder={tr('salesforce_reply.list_emails')}
          handleValueChange={(value: Option[]) =>
            handleSetState({
              Emails: value
            })
          }
        />
      </Form.Field>

      <Form.Field
        required
        error={
          validationErrors.Description && {
            content: validationErrors.Description,
            pointing: 'above'
          }
        }
        control={TextArea}
        value={payload.Description}
        label={tr('GENERAL_CONTENT')}
        onChange={(event: React.SyntheticEvent, data: { [key: string]: any }) =>
          handleSetState({
            Description: data.value
          })
        }
      />
    </>
  );
};

export default SalesforceEmailForm;
