import React, { createContext, useContext, useState } from 'react';

import type { FunctionComponent } from 'react';

type CommandsContextFunc = (() => void) | null;

interface CommandsContextType {
  closeAsDoneFunc: CommandsContextFunc;
  setCloseAsDoneFunc: (value: CommandsContextFunc) => void;
  sendAndCloseAsDoneFunc: CommandsContextFunc;
  setSendAndCloseAsDoneFunc: (value: CommandsContextFunc) => void;
}

const CommandsContext = createContext<CommandsContextType | undefined>(undefined);

export const useCommandsContext = () => {
  const context = useContext(CommandsContext);
  if (!context) {
    throw new Error('useCommandsContext must be used within a CommandsContextProvider');
  }
  return context;
};

const CommandsContextProvider: FunctionComponent<{ children?: React.ReactNode }> = ({ children }) => {
  const [closeAsDoneFunc, setCloseAsDoneFunc] = useState<CommandsContextFunc>(null);
  const [sendAndCloseAsDoneFunc, setSendAndCloseAsDoneFunc] = useState<CommandsContextFunc>(null);

  return (
    <CommandsContext.Provider
      value={{
        closeAsDoneFunc,
        setCloseAsDoneFunc,
        sendAndCloseAsDoneFunc,
        setSendAndCloseAsDoneFunc
      }}
    >
      {children}
    </CommandsContext.Provider>
  );
};

export default CommandsContextProvider;
