import { faMagnifyingGlass, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import type { ButtonProps } from 'semantic-ui-react';

import Button from '../generic/Button/Button';

interface SearchControlsProp {
  onSubmit(event: React.MouseEvent<HTMLButtonElement>, data: ButtonProps): void;
  onClear(event: React.MouseEvent<HTMLButtonElement>, data: ButtonProps): void;
}

const SearchControls: React.FC<SearchControlsProp> = ({ onSubmit, onClear }) => {
  const { t } = useTranslation();

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Button
        type="primary"
        onClick={onSubmit}
        content={t('SEARCH')}
        iconLeft={<FontAwesomeIcon icon={faMagnifyingGlass} />}
        id="ticketFilterSearchBtn"
      />
      <Button
        onClick={onClear}
        content={t('GENERAL_CLEAR')}
        iconLeft={<FontAwesomeIcon icon={faXmark} />}
        id="ticketFilterSearchClearBtn"
        type="error"
      />
    </div>
  );
};

export default SearchControls;
