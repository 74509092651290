import React from 'react';
import { Menu } from 'semantic-ui-react';

import type { FC } from 'react';

import MainTab from './MainTab';
import * as styles from './MainTabs.style';
import TicketListTab from './TicketListTab';
import { selectActiveMainTab } from 'src/selectors/rootStateSelectors';
import { useAppSelector } from 'src/store';
import { StaticTabs } from 'src/types/TicketList';

const MainTabs: FC = () => {
  const ticketListTabs = useAppSelector((state) =>
    Object.values(state.ticketListTabs).filter((tab) => tab.id !== StaticTabs.MAIN_VIEW)
  );
  const activeTab = useAppSelector(selectActiveMainTab);

  return (
    <div style={styles.wrapper}>
      <MainTab />

      {ticketListTabs.length > 0 && (
        <Menu secondary style={styles.menu}>
          {ticketListTabs.reverse().map((tab) => (
            <TicketListTab tab={tab} activeTabId={activeTab.id} />
          ))}
        </Menu>
      )}
    </div>
  );
};

export default React.memo(MainTabs);
