import { t } from 'i18next';
import React, { useCallback } from 'react';
import { Icon, Label, List } from 'semantic-ui-react';

import type { SemanticICONS, SemanticSIZES } from 'semantic-ui-react';

import { getPrettyDate } from '../../Utilities/dates';
import { timePrefix, timestampPrefixToDate } from '../Case/DateSelector';

interface ListItem {
  param: string;
  name: string;
  text?: string;
  value?: string;
  icon?: SemanticICONS;
}

interface SearchConditionListProps {
  size: SemanticSIZES;
  items: ListItem[];
  onDelete: (...args: any[]) => any;
}

const SearchConditionList: React.FC<SearchConditionListProps> = ({ size, items, onDelete }) => {
  const deleteItem = useCallback(
    (item: ListItem) => {
      onDelete(item);
    },
    [onDelete]
  );

  const displaySearchValue = useCallback((value: string) => {
    if (value.startsWith(timePrefix) && timestampPrefixToDate(value)) {
      return getPrettyDate(timestampPrefixToDate(value), { sinceCurrentTime: false, format: 'lll' });
    }

    return value;
  }, []);

  if (items.length === 0) {
    return null;
  }

  return (
    <List>
      {items.map((item, index) => {
        const value = item.text !== undefined ? item.text : item.value!;

        return (
          <List.Item key={`search-condition-list-item-${index}`}>
            <Label color="violet" size={size} style={{ width: '100%', borderRadius: '10px', padding: '10px 20px' }}>
              {item.icon && <Icon name={item.icon} />}
              {item.name === t('SEARCH_DELEGATED_TO_ME') || item.name === t('SEARCH_STATUS_ONLY_READY')
                ? `${item.name}`
                : `${item.name}: ${displaySearchValue(value)}`}
              <Icon name="delete" style={{ float: 'right' }} onClick={() => deleteItem(item)} />
            </Label>
          </List.Item>
        );
      })}
    </List>
  );
};

export default React.memo(SearchConditionList);
