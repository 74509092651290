import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useCommandsContext } from './CommandsContext';
import { fetchTemplates } from 'src/actions/templateActions';
import { setTicketlistAccordion } from 'src/actions/ticketListTabActionsRTK';
import { createNewTicket } from 'src/actions/ticketsActions';
import { selectAllowedContentTemplates } from 'src/selectors/rootStateSelectors';
import { StaticTabs } from 'src/types/TicketList';

import type { PromptOption } from './types';
import type { Template, TemplateContentJson } from 'src/types/Template';

const useCommands = (): [promptOptions: PromptOption[]] => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const templates = useSelector(selectAllowedContentTemplates);
  const { closeAsDoneFunc, sendAndCloseAsDoneFunc } = useCommandsContext();

  useEffect(() => {
    dispatch(fetchTemplates());
  }, []);

  const onCreateNewTicket = (template?: Template<TemplateContentJson>) => () => {
    dispatch(createNewTicket({ contentType: 'tickets', template }));
  };

  const onToggleListAccordion = (accordionIndex: number) => () => {
    dispatch(setTicketlistAccordion({ id: StaticTabs.MAIN_VIEW, accordionIndex }));
  };

  const commandPrompts = [
    { text: t('keyboardShortcuts.new_ticket'), shortcut: '⌥ / alt,n', action: onCreateNewTicket() },

    ...(templates.length > 0
      ? templates.map((template) => ({
          text: t('keyboardShortcuts.new_ticket_using_template', { templateName: template.name }),
          action: onCreateNewTicket(template)
        }))
      : []),

    !!closeAsDoneFunc && {
      text: t('CLOSE_AS_DONE'),
      shortcut: '⌥ / alt,c',
      action: closeAsDoneFunc
    },

    !!sendAndCloseAsDoneFunc && {
      text: t('GENERAL_SEND_AND_CLOSE'),
      shortcut: '⌥ / alt,s',
      action: sendAndCloseAsDoneFunc
    },

    { text: t('keyboardShortcuts.toggle_todo'), shortcut: '⌥ / alt,1', action: onToggleListAccordion(0) },
    { text: t('keyboardShortcuts.toggle_doing'), shortcut: '⌥ / alt,2', action: onToggleListAccordion(1) },
    { text: t('keyboardShortcuts.toggle_done'), shortcut: '⌥ / alt,3', action: onToggleListAccordion(2) }
  ]
    .filter(Boolean)
    .map((commandPrompt, index) => ({
      ...commandPrompt,
      value: index.toString()
    })) as PromptOption[];

  return [commandPrompts];
};

export default useCommands;
