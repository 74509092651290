import React from 'react';
import { ButtonGroup } from 'semantic-ui-react';

import type { FC } from 'react';
import type { ButtonGroupProps, DropdownItemProps } from 'semantic-ui-react';

import Button from 'src/Components/generic/Button/Button';
import Dropdown from 'src/Components/generic/Dropdown/Dropdown';

import type { ButtonProps } from 'src/Components/generic/Button/Button';
import type { DropdownProps } from 'src/Components/generic/Dropdown/Dropdown';

import './FloatingDropdown.css';

interface FloatingDropdownProps {
  buttonGroupProps?: ButtonGroupProps;
  buttonProps?: ButtonProps;
  dropdownProps?: DropdownProps;
  options: DropdownItemProps[];
}

const FloatingDropdown: FC<FloatingDropdownProps> = ({ buttonGroupProps, buttonProps, dropdownProps, options }) => (
  <>
    <ButtonGroup {...buttonGroupProps} className="floatingDropdownGroup">
      <Button {...buttonProps} />
      <Dropdown className="button icon floatingDropdown" options={options} trigger={<></>} {...dropdownProps} />
    </ButtonGroup>
  </>
);

export default FloatingDropdown;
