import classNames from 'classnames';
import React from 'react';

import type { FC } from 'react';

import InfoInput from './InfoInput';

import type { InfoFieldProps } from './InfoFieldProps';

interface PhoneNumberProps extends Pick<InfoFieldProps, 'field' | 'setValue' | 'onSaveData'> {
  fieldValue: any;
  inputType: 'text' | 'number';
  disabled?: boolean;
  mongoSaveDisabled?: boolean;
  integrationSaveDisabled?: boolean;
  disableWarning?: boolean;
}

const PhoneNumber: FC<PhoneNumberProps> = ({
  field,
  fieldValue,
  inputType,
  disabled,
  mongoSaveDisabled,
  integrationSaveDisabled,
  setValue,
  onSaveData,
  disableWarning
}) => {
  const className = classNames({
    'Info-CompactInput': true,
    disabledInputField: mongoSaveDisabled,
    readOnlyInputField: integrationSaveDisabled
  });

  return (
    <InfoInput
      field={field}
      fieldValue={fieldValue}
      inputType={inputType}
      disabled={!!disabled}
      className={className}
      mongoSaveDisabled={!!mongoSaveDisabled}
      integrationSaveDisabled={!!integrationSaveDisabled}
      setValue={setValue}
      onSaveData={onSaveData}
      disableWarning={disableWarning}
    />
  );
};

export default React.memo(PhoneNumber);
