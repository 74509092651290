import { connect } from 'react-redux';

import { activateTab, closeTab } from '../actions/tabActionsRTK';
import {
  activateTicket,
  createNewTicket,
  deActivateTicket,
  fetchTickets,
  scrollCaseBottomInstantly
} from '../actions/ticketsActions';
import TabBar from '../TabBar';
import { CREATE_INFO_PAGE, CREATE_TICKET } from 'src/actions';
import { roomSubscriberClosed } from 'src/actions/middlewareActions';
import { StaticTabs } from 'src/types/TicketList';

import type { State } from 'src/types/initialState';
import type { ThunkAppDispatch } from 'src/types/store';
import type { Template, TemplateContentJson } from 'src/types/Template';
import type { ContentTypesFields } from 'src/types/Ticket';

interface IProps {
  contentType: ContentTypesFields;
}

const mapStateToProps = (state: State, ownProps: IProps) => {
  return {
    user: state.usersList.usersList.find((user) => user.UID === state.userData.UID),
    tabs: state.ticketTabs.filter((tab) => tab.type === ownProps.contentType),
    personalData: state.userData,
    ticketTypes: state.ticketTypes,
    tickets: state.detailedTickets,
    isCreateTabInProgress: state.ajaxCallsInProgress.some((ajaxCall) =>
      [CREATE_TICKET, CREATE_INFO_PAGE].includes(ajaxCall.name)
    )
  };
};

const mapDispatchToProps = (dispatch: ThunkAppDispatch, ownProps: IProps) => {
  return {
    method: ownProps.contentType,
    onCreateNewTicket: (contentType: ContentTypesFields, template?: Template<TemplateContentJson>) =>
      dispatch(createNewTicket({ contentType, template })),
    onTabClose: (id: string, activeId?: string) => {
      dispatch(deActivateTicket(id));
      if (activeId) {
        dispatch(activateTicket(activeId));
        dispatch(activateTab(activeId));
      }
      dispatch(closeTab(id));
      dispatch(roomSubscriberClosed(id));
    },
    onActivateTab: (id: string) => {
      dispatch(activateTicket(id));
      dispatch(activateTab(id));
    },
    scrollCaseBottomInstantly: () => {
      dispatch(scrollCaseBottomInstantly());
    },
    refreshMainView: () => {
      dispatch(fetchTickets({}, StaticTabs.MAIN_VIEW, false));
    }
  };
};

const TicketTabBarContainer = connect(mapStateToProps, mapDispatchToProps)(TabBar);

export default TicketTabBarContainer;
