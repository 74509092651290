export interface ExportColumn {
  key: string;
  value: any;
}

export interface ExportRow {
  id: number;
  columns: ExportColumn[];
}

export interface ExportData {
  headers: { key: string; displayName?: string }[];
  rows: ExportRow[];
}

export interface ExportTemplate {
  id: number;
  name: string;
}

export enum ExportJobStatus {
  PENDING = 'pending',
  PROCESSING = 'processing',
  DONE = 'done',
  ERROR = 'error'
}

export interface ExportJob {
  id: number;
  templateId: number;
  ticketIds: number[];
  status: ExportJobStatus;
  fileId?: number;
  fileName?: string;
}
