import {
  faArrowUpRightFromSquare,
  faBookOpen,
  faChartColumn,
  faGear,
  faHouse,
  faTableList,
  faUsers
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useMemo, useState } from 'react';
import { Translation, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Divider } from 'semantic-ui-react';

import type { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import type { ReactNode } from 'react';
import type { MenuItem } from 'semantic-ui-react';

import { ListOfPresetFilters } from './ListOfPresetFilters';
import FeatureFlags from 'src/api/FeatureFlags';
import ModalLoaderComponent from 'src/Components/Loader/ModalLoader';
import ExportDataContainer from 'src/containers/ExportDataContainer';

interface MenuItem {
  icon: IconDefinition;
  text: string;
  href?: string;
}

const UnifiedMenuAndFilters = () => {
  const { t } = useTranslation();
  const [isMinimised, setIsMinimised] = useState(false);

  const wrapWithLink = (item: MenuItem, node: ReactNode) => {
    return (
      <Link to={item.href!} className="dropdown-link">
        {node}
      </Link>
    );
  };

  const wrapWithAction = (item: MenuItem & { action?: (...args: any[]) => void }, node: ReactNode) => {
    return (
      <a href="#" onClick={() => item.action?.()} className="dropdown-link" style={{ cursor: 'pointer' }}>
        {node}
      </a>
    );
  };

  const Item = (props: { item: MenuItem & { action?: (...args: any[]) => void } }) => {
    const node = (
      <div className="layout-desktop-unified-menu-item">
        <FontAwesomeIcon icon={props.item.icon} size="lg" />
        <div className="layout-desktop-unified-menu-item-text">{props.item.text}</div>
      </div>
    );

    return props.item.href
      ? wrapWithLink(props.item, node)
      : props.item.action
      ? wrapWithAction(props.item, node)
      : node;
  };

  const [exportDataModalOpen, setIsExportDataModalOpen] = useState(false);
  const setExportDataModalOpen = useCallback(() => setIsExportDataModalOpen(true), [setIsExportDataModalOpen]);
  const setExportDataModalClosed = useCallback(() => setIsExportDataModalOpen(false), [setIsExportDataModalOpen]);
  const menuItems: (MenuItem & { action?: (...args: any[]) => void })[] = useMemo(
    () => [
      {
        icon: faHouse,
        text: 'Main view',
        href: '/'
      },
      {
        icon: faBookOpen,
        text: 'Knowledge base',
        href: '/infopage'
      },
      {
        icon: faArrowUpRightFromSquare,
        text: 'Export data',
        action: setExportDataModalOpen
      },
      {
        icon: faChartColumn,
        text: 'Reporting',
        href: '/reporting'
      },
      {
        icon: faTableList,
        text: 'Aggregate view',
        href: '/aggregate'
      },
      {
        icon: faGear,
        text: 'Settings',
        href: '/settings'
      },
      ...(FeatureFlags.isFlagOn('ENABLE_ENTITY_VIEWER')
        ? [
            {
              icon: faUsers,
              text: t('entityViewer.entity_view'),
              href: '/entities'
            }
          ]
        : [])
    ],
    [setExportDataModalOpen]
  );

  return (
    <Translation>
      {() => (
        <div
          className="layout-desktop-unified-menu"
          onPointerEnter={() => setIsMinimised(false)}
          onPointerLeave={() => setIsMinimised(true)}
        >
          <div className="layout-desktop-unified-menu_items">
            {menuItems.map((item) => (
              <Item item={item} />
            ))}
          </div>
          <Divider />
          {!isMinimised && (
            <div>
              <ListOfPresetFilters />
            </div>
          )}

          {exportDataModalOpen && (
            <React.Suspense fallback={<ModalLoaderComponent />}>
              <ExportDataContainer handleClose={setExportDataModalClosed} isOpen={exportDataModalOpen} />
            </React.Suspense>
          )}
        </div>
      )}
    </Translation>
  );
};

export default UnifiedMenuAndFilters;
