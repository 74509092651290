import { SuggestionType } from '@eeedo/types';
import { debounce } from 'lodash-es';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Icon, Search } from 'semantic-ui-react';

import type { Suggestion } from '@eeedo/types';
import type { FC } from 'react';
import type { ConnectedProps } from 'react-redux';
import type { Input } from 'semantic-ui-react';

import type { State } from 'src/types/initialState';

interface OwnProps {
  onBlur: (value: string) => void;
  onChange: (value: string) => void;
  value: string;
  ticketTypeName: string;
}

type Props = TopBarTitleInputReduxProps & OwnProps;

const TopBarTitleInput: FC<Props> = ({ value, onBlur, onChange, autoSuggestions, ticketTypeName, ticketTypes }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef<Input>(null);

  const eligibleAutoSuggestions = useMemo(() => {
    const ticketTypeId = ticketTypes.find((m) => m.name === ticketTypeName)?.id;

    if (!ticketTypeId) {
      throw new Error('Ticket type mismatch');
    }

    return autoSuggestions
      .filter(({ type, ticketTypes }: Suggestion) =>
        ticketTypes.length > 0
          ? type === SuggestionType.ticketTitle && ticketTypes.includes(ticketTypeId)
          : type === SuggestionType.ticketTitle
      )
      .map(({ value }) => ({ title: value }));
  }, [autoSuggestions, ticketTypeName, ticketTypes]);

  useEffect(() => {
    onFocus();
  }, []);

  useEffect(() => {
    onBlurDebounced(value, isFocused, isDropdownOpen);
  }, [value, isDropdownOpen, isFocused]);

  const onBlurDebounced = useCallback(
    debounce((value: string, isFocused: boolean, isDropdownOpen: boolean) => {
      if (!isFocused && !isDropdownOpen) {
        onBlur(value);
      }
    }, 100),
    []
  );

  const onFocus = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const onClickIcon = () => {
    if (isDropdownOpen) {
      onFocus();
    }
    setIsDropdownOpen(!isDropdownOpen);
  };

  const onBlurSearch = () => {
    setTimeout(() => setIsFocused(false), 100);
  };

  return (
    <Search
      className="topBarTitleInput"
      fluid
      icon={<Icon name="chevron down" className="dropdownIcon link" onClick={onClickIcon} />}
      input={{ ref: inputRef }}
      minCharacters={0}
      open={isDropdownOpen}
      results={eligibleAutoSuggestions}
      search={() => eligibleAutoSuggestions}
      value={value}
      onBlur={onBlurSearch}
      onFocus={() => setIsFocused(true)}
      onResultSelect={(event, data) => {
        onChange(data.result.title);
        setIsDropdownOpen(false);
        onFocus();
      }}
      onSearchChange={(event, data) => {
        onChange(data.value || '');
      }}
    />
  );
};

function mapStateToProps(state: State) {
  return {
    ticketTypes: state.ticketTypes,
    autoSuggestions: state.suggestions
  };
}

const connector = connect(mapStateToProps, {});
type TopBarTitleInputReduxProps = ConnectedProps<typeof connector>;

export default connector(TopBarTitleInput);
