import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import type { TabFilter } from '@eeedo/types';
import type { FC } from 'react';
import type { ConnectedProps } from 'react-redux';

import { StaticTabs } from 'src/types/TicketList';
import { filterTicketList } from 'src/Utilities/ticketList';

import type { State } from 'src/types/initialState';

import './SingleLineTicketView.css';

import SingleLineTicketViewContent from './SingleLineTicketViewContent';
import { fetchInfoPages } from 'src/actions/infoPagesActions';
import { orderInfoPageList } from 'src/Utilities/infoPageList';

import type { AccordionTab } from './SingleLineTicketViewContent';

type SingleLineTicketViewInfopagesProps = ConnectedProps<typeof connector>;

const SingleLineTicketViewInfopages: FC<SingleLineTicketViewInfopagesProps> = ({
  ticketListTab,
  categories,
  personalData,
  usersList,
  fetchInfoPages
}) => {
  const filters = ticketListTab?.filters ?? ({} as TabFilter);
  const { sorting, direction } = ticketListTab || {};
  const orderedTickets = orderInfoPageList(ticketListTab?.infoPages || ([] as any), sorting as any, direction as any);
  useEffect(() => {
    fetchInfoPages({}, StaticTabs.MAIN_VIEW, false);
  }, []);

  const filterArgs = {
    categories,
    personalData,
    filters,
    usersList
  };
  const draftTickets = useMemo(
    () =>
      filterTicketList({
        ...filterArgs,
        status: 'draft',
        tickets: orderedTickets.filter((ticket) => ticket.status === 'draft')
      }),
    [categories, personalData, filters, orderedTickets, usersList]
  );
  const inReviewTickets = useMemo(
    () =>
      filterTicketList({
        ...filterArgs,
        status: 'inReview',
        tickets: orderedTickets.filter((ticket) => ticket.status === 'inReview')
      }),
    [categories, personalData, filters, orderedTickets, usersList]
  );
  const waitingToBePublishedTickets = useMemo(
    () =>
      filterTicketList({
        ...filterArgs,
        tickets: orderedTickets.filter((ticket) => ticket.status === 'waitingToBePublished'),
        status: 'waitingToBePublished'
      }),
    [categories, personalData, filters, orderedTickets, usersList]
  );
  const publishedTickets = useMemo(
    () =>
      filterTicketList({
        ...filterArgs,
        tickets: orderedTickets.filter((ticket) => ticket.status === 'published'),
        status: 'published'
      }),
    [categories, personalData, filters, orderedTickets, usersList]
  );
  const wasteTickets = useMemo(
    () =>
      filterTicketList({
        ...filterArgs,
        tickets: orderedTickets.filter((ticket) => ticket.status === 'waste'),
        status: 'waste'
      }),
    [categories, personalData, filters, orderedTickets, usersList]
  );

  const accordionTabs: AccordionTab[] = useMemo(
    () => [
      { index: 0, status: 'draft', tickets: draftTickets },
      { index: 1, status: 'inReview', tickets: inReviewTickets },
      { index: 2, status: 'waitingToBePublished', tickets: waitingToBePublishedTickets },
      { index: 3, status: 'published', tickets: publishedTickets },
      { index: 4, status: 'waste', tickets: wasteTickets }
    ],
    [draftTickets, inReviewTickets, waitingToBePublishedTickets]
  );

  return (
    <SingleLineTicketViewContent
      accordionTabs={accordionTabs}
      dropdownOptions={[
        { text: 'Move to Waste', value: 'waste' },
        { text: 'Publish', value: 'publish' }
      ]}
    />
  );
};

const connector = connect(
  (state: State) => {
    const activeTab = Array.from(state.infoPageListTabs.values()).find((tab) => tab.activeTab);

    return {
      ticketListTab: state.infoPageListTabs.get(activeTab?.id ?? StaticTabs.MAIN_VIEW),
      usersList: state.usersList.usersList,
      categories: state.categories,
      personalData: state.userData,
      ticketTypes: state.ticketTypes,
      channels: state.channels,
      priorities: state.ticketPriorities
    };
  },
  { fetchInfoPages }
);

export default withRouter(connector(SingleLineTicketViewInfopages));
