import classNames from 'classnames';
import React from 'react';
import { Checkbox } from 'semantic-ui-react';

import type { FC } from 'react';
import type { CheckboxProps } from 'semantic-ui-react';

import './Toggle.css';

type ToggleType = 'primary' | 'normal' | 'error';

interface ToggleProps extends CheckboxProps {
  // Note: 'type' already reserved by CheckboxProps
  colorType?: ToggleType;
}

const Toggle: FC<ToggleProps> = ({ colorType = 'primary', ...props }) => {
  const buttonClass = classNames('eeedoToggle', `type__${colorType}`);

  return <Checkbox className={buttonClass} {...props} toggle={true} />;
};

export default Toggle;
