import { useHotkeys } from 'react-hotkeys-hook';

import type { FC } from 'react';

interface Props {
  onCreateNewTicket: () => void;
}

const ShortcutsTopBar: FC<Props> = ({ onCreateNewTicket }) => {
  useHotkeys('alt+n', onCreateNewTicket, undefined, [onCreateNewTicket]);

  return null;
};

export default ShortcutsTopBar;
