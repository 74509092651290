import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Icon, Menu } from 'semantic-ui-react';

import type { FC } from 'react';

import { addTab } from 'src/actions/tabActionsRTK';
import { fetchTicket } from 'src/actions/ticketsActions';
import SocketInstance from 'src/realTimeNotifications';
import { setTabsPersist, typeKeyMap } from 'src/reducers/tabsPersistReducer';

import type { State } from 'src/types/initialState';
import type { ContentTypesFields } from 'src/types/Ticket';

const selectPreviousTabsIds = (contentType: ContentTypesFields) => (state: State) =>
  state.tabsPersist[typeKeyMap[contentType]];
const selectCurrentTicketTabsIds = (contentType: ContentTypesFields) => (state: State) =>
  state.ticketTabs.filter(({ type }) => type === contentType).map(({ id }) => id);

interface Props {
  contentType: ContentTypesFields;
}

const TabBarRestore: FC<Props> = ({ contentType }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currentTicketsIds = useSelector(selectCurrentTicketTabsIds(contentType));
  const previousTicketsIds = useSelector(selectPreviousTabsIds(contentType));
  const ticketIdsToRestore = previousTicketsIds.filter((id) => !currentTicketsIds.includes(id));

  const onClickClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    dispatch(
      setTabsPersist({
        ids: currentTicketsIds,
        type: contentType
      })
    );
  };

  const onClickRestore = () => {
    ticketIdsToRestore.forEach((id) => openTicket(id));
  };

  const openTicket = (id: string) => {
    SocketInstance.joinRoom(id);
    dispatch(addTab({ id, type: contentType, activeTab: false }));
    dispatch(
      fetchTicket({
        id,
        shouldActivateTicket: false,
        ...(contentType === 'infopages' && { type: 'infopage' })
      })
    );
  };

  if (!ticketIdsToRestore.length) {
    return null;
  }

  return (
    <Menu.Item className="pulse-active" onClick={onClickRestore}>
      {t('RESTORE_PREVIOUS_TABS')}
      <Icon className="TabBar-Delete-Icon" name="delete" onClick={onClickClose} />
    </Menu.Item>
  );
};

export default TabBarRestore;
