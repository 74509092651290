import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';

import type { FC } from 'react';

import AccordionHeader from '../../AccordionHeader';
import SurveyAnswer from './SurveyAnswer';
import UserLabel from 'src/Components/User/UserLabel';
import { DATE_TIME_FORMAT, getPrettyDate } from 'src/Utilities/dates';

import type { SurveyAnswer as SurveyAnswerType } from 'src/types/Survey';

import './Survey.css';

interface SurveyProps {
  survey: SurveyAnswerType;
  complete: boolean;
  getAndCopyLink: (surveyId: number) => void;
}

const Survey: FC<SurveyProps> = ({ survey, getAndCopyLink, complete }) => {
  const { t } = useTranslation();

  const { id: answerId, answeredAt, answers, feedback, handledByUserId, sendAt, sendTo, surveyId, sendMethod } = survey;

  return (
    <AccordionHeader key={answerId} as="h4" active title={`${t('CASE_SURVEY')} ${answerId}`} icon="envelope">
      {handledByUserId !== null && (
        <div>
          <strong>{t('SURVEY_WIDGET_HANDLED_BY_UID')}:</strong>
          <br />
          <UserLabel UID={handledByUserId} />
        </div>
      )}
      <div className={`${complete ? 'hidden' : 'info'}`}>
        <Icon name="hourglass half" size="small" />
        {t('CASE_SURVEY_PENDING')}
      </div>
      {!complete && sendMethod?.toUpperCase() === 'MANUAL' && (
        <div>
          <strong>Link: </strong>
          <Link to="#" onClick={() => getAndCopyLink(surveyId)}>
            {t('CASE_SURVEY_COPY_LINK')}
          </Link>
        </div>
      )}
      <div className={`${complete ? '' : 'hidden'}`}>
        {answers.map((a, index) => (
          <SurveyAnswer key={index} question={a.question} answer={a.answer} />
        ))}
      </div>
      <div className={`${complete && feedback ? 'quote' : 'hidden'}`}>
        <Icon name="quote right" size="small" />
        {feedback}
      </div>
      <div className={`${complete ? '' : 'hidden'}`}>
        <strong>{t('CASE_SURVEY_ANSWERED_AT')}:</strong> {formatDate(answeredAt)}
      </div>
      <div>
        <strong>{t('CASE_SURVEY_SENT_AT')}:</strong> {formatDate(sendAt)}
      </div>
      <div>
        {sendTo && (
          <span>
            <strong>{t('CASE_SURVEY_SENT_TO')}:</strong> {sendTo}
          </span>
        )}
      </div>
    </AccordionHeader>
  );
};

const formatDate = (date: number): string => getPrettyDate(date, { format: DATE_TIME_FORMAT });

export default Survey;
