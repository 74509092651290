import { useHotkeys } from 'react-hotkeys-hook';

import type { FC } from 'react';

const ShortcutsReplyChannel: FC = () => {
  const focusContent = () => {
    const contentFroalaEditor = document.querySelector<HTMLTextAreaElement>('#commentContentField .fr-element');
    const contentTextarea = document.querySelector<HTMLTextAreaElement>('#commentContentField textarea');

    if (contentFroalaEditor) {
      contentFroalaEditor.focus();
    } else if (contentTextarea) {
      contentTextarea.focus();
    }
  };

  useHotkeys('alt+a', focusContent, undefined, [focusContent]);

  return null;
};

export default ShortcutsReplyChannel;
