import { faTableColumns } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import type { FC } from 'react';

import Button from '../generic/Button/Button';
import { setSingleLineComments } from 'src/reducers/commentsMenuReducer';

import type { State } from 'src/types/initialState';

const SingleLineCommentsToggle: FC = () => {
  const dispatch = useDispatch();
  const singleLineComments = useSelector<State, boolean>((state) => state.commentsMenu.singleLineComments);
  const toggleSingleLineComments = useCallback(
    () => dispatch(setSingleLineComments(!singleLineComments)),
    [singleLineComments]
  );

  return (
    <Button
      type={singleLineComments ? 'primary' : 'normal'}
      iconLeft={<FontAwesomeIcon icon={faTableColumns} />}
      onClick={toggleSingleLineComments}
    />
  );
};

export default SingleLineCommentsToggle;
