import { far, faSparkles } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { camelCase } from 'lodash-es';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'semantic-ui-react';

import type { CSSProperties } from 'react';

import EnvSettings from 'src/api/EnvSettings';
import { DEFAULT_HEIGHT, TICKET_WINDOW_WIDTH } from 'src/Components/generic/FloatingWindow/constants';
import { addFloatingWindow } from 'src/reducers/floatingWindowsReducer';
import { FloatingWindowTypes } from 'src/types/FloatingWindow';

import type { State } from 'src/types/initialState';

const aiAssistantButtonStyles: CSSProperties = {
  position: 'fixed',
  bottom: 0,
  right: 0,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '250px',
  height: '45px',
  margin: 0,
  padding: '10px 15px 7px',
  borderRadius: '.75rem 0 0 0'
};

const AiAssistantButton = () => {
  const dispatch = useDispatch();
  const aiFloatingWindow = useSelector((state: State) =>
    state.windows.find((window) => window.id === FloatingWindowTypes.AIAssistant)
  );
  const { AI_ASSISTANT: aiAssistantSettings } = EnvSettings.getSettings();
  const { name, iconName } = aiAssistantSettings!.data ?? {};

  const openAiAssistant = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    dispatch(
      addFloatingWindow({
        title: 'Muusa tekoälyavustin',
        id: FloatingWindowTypes.AIAssistant,
        type: FloatingWindowTypes.AIAssistant,
        width: TICKET_WINDOW_WIDTH,
        data: {} as any,
        // testing: 10 and 25 is like a margin here
        x: window.innerWidth - TICKET_WINDOW_WIDTH - 10,
        y: window.innerHeight - DEFAULT_HEIGHT - 25
      })
    );
  };

  return !aiFloatingWindow ? (
    <Button positive onClick={openAiAssistant} title={name} size="big" style={aiAssistantButtonStyles}>
      Muusa
      <FontAwesomeIcon icon={far[camelCase(iconName)] ?? faSparkles} size="lg" />
    </Button>
  ) : null;
};

export default AiAssistantButton;
