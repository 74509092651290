import React, { useCallback, useEffect, useRef, useState } from 'react';

import type { CSSProperties, FC, ReactNode } from 'react';

import * as styles from './Collapsible.style';

interface CollapsibleProps {
  heading: ((isOpen: boolean) => ReactNode) | ReactNode;
  children: ReactNode;

  style?: CSSProperties;
  openByDefault?: boolean;
}

const Collapsible: FC<CollapsibleProps> = ({ heading, style, openByDefault = false, children }) => {
  const [isOpen, setIsOpen] = useState(openByDefault);
  const [overflow, setOverflow] = useState<CSSProperties['overflow']>('hidden');
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setOverflow('visible');
      }, 300);
    } else {
      setOverflow('hidden');
    }
  }, [isOpen]);

  const toggleCollapse = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen, setIsOpen]);

  const getContentHeight = () => (isOpen ? `${contentRef.current?.scrollHeight ?? 0}px` : '0px');

  return (
    <div style={style}>
      <div onClick={toggleCollapse} style={styles.headerStyle}>
        {typeof heading === 'function' ? heading(isOpen) : heading}
      </div>
      <div ref={contentRef} style={{ ...styles.contentStyle, height: getContentHeight(), overflow }}>
        {children}
      </div>
    </div>
  );
};

export default React.memo(Collapsible);
