import {
  // faBars,
  faCalendar,
  faIdCard,
  faInfoCircle,
  faPaperclip,
  faPenToSquare,
  faTag,
  faUpload,
  faUserPlus
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';

import type { TicketType } from '@eeedo/types';

import { useWidgetView } from 'src/Components/Case/Widget/WidgetViewContext';
import { getWidgetOrder } from 'src/Components/Utilities/widgets';

import type { State } from 'src/types/initialState';
import type { Ticket } from 'src/types/Ticket';

// TODO: hardcoded for demo, should map through the widgets
const items = [
  { icon: <FontAwesomeIcon size="lg" icon={faCalendar} />, widgetName: 'RescheduleCallback' },
  { icon: <FontAwesomeIcon size="lg" icon={faTag} />, widgetName: 'Tags' },
  { icon: <FontAwesomeIcon size="lg" icon={faUserPlus} />, widgetName: 'AttachEntityById' },
  { icon: <FontAwesomeIcon size="lg" icon={faInfoCircle} />, widgetName: 'SuggestedArticles' },
  { icon: <FontAwesomeIcon size="lg" icon={faPenToSquare} />, widgetName: 'TicketRelations' },
  { icon: <FontAwesomeIcon size="lg" icon={faIdCard} />, widgetName: 'CustomerInfo' },
  // { icon: <FontAwesomeIcon size="lg" icon={faBars} />, widgetName: 'RescheduleCallback' },
  { icon: <FontAwesomeIcon size="lg" icon={faPaperclip} />, widgetName: 'Attachments' },
  { icon: <FontAwesomeIcon size="lg" icon={faUpload} />, widgetName: 'Priority' }
];

export const DesktopLayoutWidgetMenu = () => {
  const { selectedWidget, setSelectedWidget, isSingleWidgetView } = useWidgetView();
  const wrapperClass = classNames('layout-desktop-widget-side-menu', {
    singleWidget: !!isSingleWidgetView
  });
  const currentTask = useSelector<State>((state) =>
    state.detailedTickets.find((ticket) => ticket.id === state.activeTicketTab)
  ) as Ticket;
  const ticketTypes = useSelector<State>((state) => state.ticketTypes) as TicketType[];
  const isSettingsPage = useSelector<State>((state) => state.router.location?.pathname.includes('/settings'));
  const isActiveTask = useSelector<State>(
    (state) => !!state.activeTicketTab && state.router.location?.pathname !== '/'
  );

  if (!isSettingsPage && !isActiveTask) return null;

  const widgets = getWidgetOrder(currentTask, ticketTypes);

  return (
    <div className={wrapperClass}>
      {items
        .filter((item) => widgets.find((widget) => widget.name === item.widgetName))
        .map((item) => {
          const itemClass = classNames('icon-wrapper', {
            active: item.widgetName === selectedWidget
          });

          return (
            <div className={itemClass} onClick={() => setSelectedWidget(item.widgetName)}>
              {item.icon}
            </div>
          );
        })}
    </div>
  );
};
