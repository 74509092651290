import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import iziToast from 'izitoast';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dimmer, Loader, Modal, Popup } from 'semantic-ui-react';

import type { FC } from 'react';

import Button from 'src/Components/generic/Button/Button';

import type { OnButtonClick } from 'src/types/Campaign';

interface EntityDeletionModalProps {
  deleteEntity: () => Promise<void>;
  mini?: boolean;
}

const EntityDeletionModal: FC<EntityDeletionModalProps> = ({ deleteEntity, mini = false }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const openModal = useCallback(() => {
    setOpen(true);
  }, []);
  const closeModal = useCallback(() => {
    setOpen(false);
  }, []);
  const onDeleteClick = useCallback<OnButtonClick>(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      setIsLoading(true);
      deleteEntity()
        .then(closeModal)
        .catch((error) => {
          console.error(error);
          iziToast.error({
            message: t('entityViewer.entity_delete_failed'),
            timeout: 3000,
            position: 'bottomRight'
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [deleteEntity]
  );

  return (
    <Modal
      open={open}
      onClose={closeModal}
      data-test="deletionModal"
      trigger={
        mini ? (
          <Popup
            trigger={
              <Button
                icon={<FontAwesomeIcon icon={faTrash} />}
                onClick={openModal}
                type="error"
                data-test="triggerDeleteMini"
              />
            }
            content={t('GENERAL_DELETE')}
          />
        ) : (
          <Button content={t('GENERAL_DELETE')} onClick={openModal} type="error" />
        )
      }
    >
      <Dimmer active={isLoading} inverted>
        <Loader inverted>{t('LOADING')}</Loader>
      </Dimmer>
      <Modal.Header>{t('entityViewer.delete_entity')}</Modal.Header>
      <Modal.Content>{t('entityViewer.delete_entity_prompt')}</Modal.Content>
      <Modal.Actions data-test="additionalActions" style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button onClick={onDeleteClick} type="primary" data-test="deleteButton">
          {t('GENERAL_ACCEPT')}
        </Button>
        <Button onClick={closeModal} type="normal" data-test="cancelDeleteButton">
          {t('NO')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default EntityDeletionModal;
