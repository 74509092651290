import { DATE_TIME_SECONDS_DASHED_FORMAT, getPrettyDate } from 'src/Utilities/dates';
import type { ExportColumn, ExportData } from 'src/types/Export';

export const transformXlsxJsonToExportData = (data: string[][]): ExportData => {
  const dataHeader: string[] = data.shift() || [];
  return {
    headers: dataHeader.map((header, index) => ({
      displayName: header,
      key: index.toString()
    })),
    rows: data.map((row: string[], rowIndex: number) => ({
      id: rowIndex,
      columns: row.map((column: string, columnIndex: number) => ({
        value: column,
        key: columnIndex.toString()
      }))
    }))
  };
};

const dateFields = [
  'created',
  'modified',
  'touched',
  'dueDate',
  'originalDueDate',
  'startedWorking',
  'finishedWorking',
  'lastContact',
  'firstResponseTime',
  'dueDateOnFirstTaskClose',
  'firstResponseWorkHours',
  'firstTaskCloseTime',
  'firstTaskCloseWorkHours'
];

export const convertExportRowToText = (
  headers: { key: string; displayName?: string }[],
  row: ExportColumn,
  columnIndex: number
) => {
  const { value } = row;

  if (dateFields.includes(headers[columnIndex].key)) {
    return getPrettyDate(value as number, {
      sinceCurrentTime: false,
      format: DATE_TIME_SECONDS_DASHED_FORMAT
    });
  }

  switch (typeof value) {
    case 'string':
    case 'number':
    case 'boolean':
      return value;

    case null:
      return '';

    case 'object':
      return JSON.stringify(value);

    default:
      return '';
  }
};
