import React from 'react';

import { useFocusMode } from '../FocusMode/FocusModeContext';
import TicketListTab from '../ticketList/TicketListTab';
import MainTabs from './MainTabs/MainTabs';
import ErrorBoundary from 'src/ErrorBoundary';
import { getUrlSearchParam } from 'src/Utilities/helper';

const TicketsSidebar = () => {
  const { isFocusMode } = useFocusMode();

  const divStyle = {
    maxWidth: isFocusMode ? '0px' : '100%',
    overflow: 'hidden',
    transition: 'max-width 0.5s ease'
  };

  const hideTicketListPaths = ['/settings', '/entities'];
  const onSettingsPage = hideTicketListPaths.some((path) => location.pathname.startsWith(path));
  const isInfopage = location?.pathname.startsWith('/infopage');

  if (getUrlSearchParam('hideNavigation')) {
    return null;
  }

  return (
    <div
      className="sideBar"
      style={{ display: onSettingsPage ? 'none' : 'flex', ...divStyle, backgroundColor: 'white', borderRight: '0' }}
    >
      <ErrorBoundary>
        <MainTabs />
      </ErrorBoundary>

      <div className="flexSideView">
        <ErrorBoundary>
          <TicketListTab contentType={isInfopage ? 'infopages' : 'tickets'} />
        </ErrorBoundary>
      </div>
    </div>
  );
};

export default TicketsSidebar;
