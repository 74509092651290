import type { TicketType, Widget, WidgetName } from '@eeedo/types';

import type { Ticket } from 'src/types/Ticket';

const defaultWidgetOrder: WidgetName[] = [
  'SuggestedArticles',
  'CaseChannel',
  'Priority',
  'HandleStatus',
  'Delegations',
  'Tags',
  'AttachEntityById',
  'CustomerInfo',
  'FieldSets',
  'ExternalLinks',
  'Surveys',
  'Attachments'
];

const defaultWidgets: Widget[] = defaultWidgetOrder.map((name) => ({ name, defaultClosed: false }));

export const getWidgetOrder = (task?: Ticket, ticketTypes?: TicketType[], selectedWidget?: string | undefined) => {
  const ticketTypesData = (ticketTypes || []).find(({ name }) => name === task?.taskType);
  if (!ticketTypesData) {
    return defaultWidgets;
  }

  const ticketTypesWidgetOrder = ticketTypesData.widgetOrder?.length
    ? ticketTypesData.widgetOrder.map((widget) =>
        typeof widget === 'string' ? ({ name: widget, defaultClosed: false } as Widget) : widget
      )
    : [];
  const widgetOrder = [
    ...ticketTypesWidgetOrder,
    ...defaultWidgets.filter((dWidget) => !ticketTypesWidgetOrder.some((tWidget) => tWidget.name === dWidget.name))
  ].filter((widget) => (widget.show === undefined ? true : widget.show));

  return selectedWidget ? widgetOrder.filter((widget) => widget.name === selectedWidget) : widgetOrder;
};
