import { faCalendar } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Popup } from 'semantic-ui-react';

import type { FC } from 'react';

import Button from '../generic/Button/Button';
import { setShowEvents } from 'src/reducers/commentsMenuReducer';

import type { State } from 'src/types/initialState';

const EventsToggle: FC = () => {
  const { t } = useTranslation();
  const showEvents = useSelector<State, boolean>((state) => !!state.commentsMenu.showEvents);
  const dispatch = useDispatch();
  const onClick = () => {
    dispatch(setShowEvents(!showEvents));
  };

  return (
    <Popup
      trigger={
        <Button
          icon={<FontAwesomeIcon icon={faCalendar} />}
          onClick={onClick}
          type={showEvents ? 'primary' : 'normal'}
        />
      }
    >
      <Popup.Content>{showEvents ? t('comments.popups.hide_events') : t('comments.popups.show_events')}</Popup.Content>
    </Popup>
  );
};

export default EventsToggle;
