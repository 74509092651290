import { DefaultTicketsOrdering } from '@eeedo/types';
import { createSlice } from '@reduxjs/toolkit';

import type { Direction, SortBy } from '@eeedo/types';

import { activateTicketlistTab, addTicketlistTab, setTicketlistSearch } from 'src/actions/CaseListActions';
import {
  clearTicketlistFilter,
  closeTicketlistTab,
  setTicketlistAccordion,
  setTicketlistFilter,
  setTicketlistSorting
} from 'src/actions/ticketListTabActionsRTK';
import {
  addTicketToTicketlist,
  fetchTicketsError,
  fetchTicketsSuccess,
  fetchTicketSuccess,
  removeTicketFromTicketlist,
  updateTicketInTicketlist
} from 'src/actions/ticketsActionsRTK';
import { StaticTabs } from 'src/types/TicketList';
import { getURLFilterParams, getURLParam } from 'src/Utilities/helper';

import type { TicketListTab } from 'src/types/TicketList';

export type TicketListTabReducerState = Record<string, TicketListTab>;

const [defaultDirection, defaultSorting] = DefaultTicketsOrdering;
const search = window.location.search;
const sorting = getURLParam<SortBy>(search, 'sorting') ?? defaultSorting;
const direction = getURLParam<Direction>(search, 'direction') ?? defaultDirection;

// TODO: probably would be more efficient to use Map
const initialState: TicketListTabReducerState = {
  [StaticTabs.MAIN_VIEW]: {
    id: StaticTabs.MAIN_VIEW,
    title: StaticTabs.MAIN_VIEW,
    activeTab: true,
    hasError: false,
    filters: getURLFilterParams(window.location.search),
    tickets: [],
    searchCriteria: [],
    accordionIndex: 0,
    rowIndex: undefined,
    sorting,
    direction
  }
};

const tabsSlice = createSlice({
  name: 'ticketListTab',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(clearTicketlistFilter, (draft, action) => {
        const ticketListTab = draft[action.payload];
        if (ticketListTab) {
          ticketListTab.filters = {};
          ticketListTab.sorting = defaultSorting;
          ticketListTab.direction = defaultDirection;
        }
      })
      .addCase(setTicketlistFilter, (draft, action) => {
        const ticketListTab = draft[action.payload.id];
        if (ticketListTab) {
          ticketListTab.filters = {
            ...ticketListTab.filters,
            [action.payload.parameter]: action.payload.value
          };
        }
      })
      .addCase(fetchTicketsError, (draft, action) => {
        const ticketListTab = draft[action.payload];
        if (ticketListTab) {
          ticketListTab.hasError = true;
        }
      })
      .addCase(fetchTicketsSuccess, (draft, action) => {
        const ticketListTab = draft[action.payload.id];
        if (ticketListTab) {
          ticketListTab.tickets = action.payload.tickets;
          ticketListTab.hasError = false;
        }
      })
      .addCase(addTicketToTicketlist, (draft, action) => {
        const ticketListTab = draft[action.payload.id];
        const ticketExists = !!ticketListTab?.tickets.find((t) => t.id === action.payload.ticket.id);
        if (ticketListTab && !ticketExists) {
          ticketListTab.tickets.push(action.payload.ticket);
        }
      })
      .addCase(removeTicketFromTicketlist, (draft, action) => {
        const ticketListTab = draft[action.payload.id];
        ticketListTab.tickets = ticketListTab.tickets.filter((ticket) => ticket.id !== action.payload.ticketId);
      })
      .addCase(updateTicketInTicketlist, (draft, action) => {
        // This action is also for adding ticket to ticket list by realtimeservice
        const ticketListTab = draft[action.payload.id];
        if (ticketListTab) {
          const ticketIndex = ticketListTab.tickets.findIndex((t) => t.id === action.payload.ticket.id);
          if (ticketIndex !== -1) {
            // Update
            ticketListTab.tickets[ticketIndex] = action.payload.ticket;
          } else {
            // Add
            ticketListTab.tickets.push(action.payload.ticket);
          }
        }
      })
      .addCase(addTicketlistTab, (draft, action) => {
        if (!draft[action.payload.id]) {
          draft[action.payload.id] = action.payload as TicketListTab;
        }
      })
      .addCase(closeTicketlistTab, (draft, action) => {
        if (action.payload !== StaticTabs.MAIN_VIEW) {
          delete draft[action.payload];
        }
      })
      .addCase(activateTicketlistTab, (draft, action) => {
        for (const [tabName, tab] of Object.entries(draft)) {
          if (tabName === action.payload) {
            tab.activeTab = true;
          } else {
            tab.activeTab = false;
          }
        }
      })
      .addCase(fetchTicketSuccess, (draft, action) => {
        const { payload } = action;
        const tickets = draft[StaticTabs.MAIN_VIEW]?.tickets;
        const ticketIndex = tickets?.findIndex(({ id }) => payload.ticket.id === id);

        if (tickets && ticketIndex !== -1) {
          tickets[ticketIndex] = {
            ...payload.ticket,
            comments: payload.ticket.comments || []
          };
        }
      })
      .addCase(setTicketlistSearch, (draft, action) => {
        const ticketListTab = draft[action.payload.id];
        if (ticketListTab) {
          ticketListTab.searchCriteria = action.payload.searchCriteria;
        }
      })
      .addCase(setTicketlistAccordion, (draft, action) => {
        const ticketListTab = draft[action.payload.id];
        if (ticketListTab) {
          ticketListTab.accordionIndex = action.payload.accordionIndex!;
        }
      })
      .addCase(setTicketlistSorting, (draft, action) => {
        const activeTab = Object.values(draft).find((t) => t.activeTab);
        if (activeTab) {
          activeTab.sorting = action.payload.sorting;
          activeTab.direction = action.payload.direction;
        }
      });
  }
});

export default tabsSlice.reducer;
