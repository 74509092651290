import React, { useRef } from 'react';

import type { FC, HTMLAttributes, PropsWithChildren } from 'react';

import { StickyContext } from './Sticky';

const StickyWrapper: FC<PropsWithChildren<HTMLAttributes<HTMLDivElement>>> = ({ children, ...props }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  return (
    <StickyContext.Provider value={{ currentWrapper: wrapperRef.current }}>
      <div ref={wrapperRef} {...props}>
        {children}
      </div>
    </StickyContext.Provider>
  );
};

export default StickyWrapper;
