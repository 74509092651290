import { useEffect } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

import type { FC } from 'react';

import { useCommandsContext } from '../Layout/Desktop/CommandPalette/CommandsContext';

interface Props {
  onCloseAsDone: () => void;
}

const ShortcutsStatusButtons: FC<Props> = ({ onCloseAsDone }) => {
  useHotkeys('alt+c', onCloseAsDone, undefined, [onCloseAsDone]);

  const { setCloseAsDoneFunc } = useCommandsContext();
  useEffect(() => {
    setCloseAsDoneFunc(() => onCloseAsDone);
    return () => setCloseAsDoneFunc(null);
  }, [onCloseAsDone]);

  return null;
};

export default ShortcutsStatusButtons;
