import classNames from 'classnames';
import React from 'react';
import { Button as SemanticButton } from 'semantic-ui-react';

import type { CSSProperties, FC, ReactNode } from 'react';
import type { ButtonProps as SemanticButtonProps } from 'semantic-ui-react';

import './Button.css';

type ButtonSize = 'sm' | 'md' | 'lg';
type ButtonType = 'primary' | 'normal' | 'error' | 'positive';

export interface ButtonProps {
  id?: string;
  disabled?: boolean;
  content?: ReactNode;
  iconLeft?: ReactNode;
  iconRight?: ReactNode;
  size?: ButtonSize;
  type?: ButtonType;
  fullWidth?: boolean;
  borderless?: boolean;
  style?: CSSProperties;
  icon?: React.ReactNode;
  center?: boolean;
  htmlType?: 'button' | 'submit' | 'reset';
  children?: ReactNode;

  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, data: SemanticButtonProps) => void;
}

const Button: FC<ButtonProps> = ({
  id,
  disabled,
  content,
  iconLeft,
  iconRight,
  size = 'md',
  type = 'normal',
  fullWidth = false,
  borderless = false,
  icon,
  center,
  style,
  htmlType,
  children,

  onClick
}: ButtonProps) => {
  const buttonClass = classNames('eeedoButton', `size__${size}`, `type__${type}`, {
    iconOnly: !!icon,
    center: !!center,
    borderless: !!borderless,
    fullWidth: !!fullWidth
  });

  return (
    <SemanticButton id={id} type={htmlType} style={style} className={buttonClass} disabled={disabled} onClick={onClick}>
      {iconLeft && <span className="icon-wrapper">{iconLeft}</span>}
      {(content || children) && <span className="content-wrapper">{content ?? children}</span>}
      {icon && <span className="icon-wrapper">{icon}</span>}
      {iconRight && <span className="icon-wrapper">{iconRight}</span>}
    </SemanticButton>
  );
};

export default Button;
