import iziToast from 'izitoast';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon } from 'semantic-ui-react';

import type { Channel, SenderEmail } from '@eeedo/types';
import type { TFunction } from 'react-i18next';

import type { Drafts, ReplyTabIndex } from '../../types/Drafts';

interface SuggestionEditorProps {
  comment: string;
  channel: number;
  drafts: Drafts;
  changeDraftContents: (payload: any, taskID: string, replyChannel: string, replyChannelTabIndex: number) => void;
  taskId: string;
  metaData: {
    [x: string]: any;
  };
  title: string;
  senderEmails: SenderEmail[];
  created: number;
  isHTML: boolean;
  attachmentIds: string[];
  replyTabIndexList: ReplyTabIndex[];
  channels: Channel[];
}

interface Update {
  content: string;
  [x: string]: unknown;
}

const findTheCorrectDraftOfSuggestion = (channelName: string, ticketId: string, drafts: Drafts) => {
  let draftMatch = undefined;
  let ticketMatch = undefined as any;

  Object.keys(drafts).find((draftsKey: string) => {
    if (draftsKey === ticketId) {
      ticketMatch = drafts[draftsKey];
      return true;
    } else {
      return false;
    }
  });

  if (ticketMatch) {
    Object.keys(ticketMatch).find((replyDraftKey: string) => {
      if (replyDraftKey === channelName) {
        draftMatch = ticketMatch[replyDraftKey].content;
        return true;
      } else {
        return false;
      }
    });
  }
  return draftMatch || '';
};

const replaceNewLineCharactersWithBreakTags = (value: string) => {
  const newValue = value.replace(/(?:\r\n|\r|\n|↵)/gm, '<br/>');
  return newValue;
};

const matchReplyChannelTabIndexAndName = (
  channelReplyTabIndexList: ReplyTabIndex[],
  contactChannels: Channel[],
  commentChannelId: number
) => {
  try {
    if (contactChannels !== undefined) {
      const channelMatch = contactChannels.find((channel: Channel) => {
        return channel.id === commentChannelId;
      });
      if (channelMatch) {
        const replyChannelTabMatch = channelReplyTabIndexList.find((replyChannelTabIndex) => {
          return replyChannelTabIndex.name === channelMatch.channel;
        });
        if (replyChannelTabMatch !== undefined) {
          return { name: replyChannelTabMatch.name, index: replyChannelTabMatch.index };
        } else {
          throw new Error('error at matchReplyChannelTabIndexAndName');
        }
      } else {
        throw new Error('error at matchReplyChannelTabIndexAndName');
      }
    } else {
      throw new Error('error at matchReplyChannelTabIndexAndName');
    }
  } catch (error) {
    return { name: 'email', index: 1 };
  }
};

const formatSuggestionToDraft = (content: string, originalContent: string, tr: TFunction): Promise<Update | null> => {
  return new Promise((resolve) => {
    let concatted = '';
    if (originalContent !== '') {
      iziToast.question({
        timeout: 0,
        progressBar: false,
        close: false,
        overlay: true,
        id: 'question',
        zindex: 999,
        message: tr('TEMPLATE_TEXT_AREA_ALREADY_HAS_TEXT'),
        position: 'center',
        buttons: [
          [
            `<button><b>${tr('GENERAL_REPLACE')}</b></button>`,
            (instance, toast) => {
              instance.hide({ transitionOut: 'fadeOut' }, toast, 'confirm');
              concatted = content;
              resolve({ content: concatted });
            },
            true
          ],
          [
            `<button>${tr('GENERAL_CONCAT_END')}</button>`,
            (instance, toast) => {
              instance.hide({ transitionOut: 'fadeOut' }, toast, 'cancel');
              concatted = replaceNewLineCharactersWithBreakTags(`${originalContent}<br/><p>${content}</p>`);
              resolve({ content: concatted });
            },
            false
          ],
          [
            `<button>${tr('GENERAL_CONCAT_START')}</button>`,
            (instance, toast) => {
              instance.hide({ transitionOut: 'fadeOut' }, toast, 'cancel');
              concatted = replaceNewLineCharactersWithBreakTags(`<br/>${content}<br/>${originalContent}<br/>`);
              resolve({ content: concatted });
            },
            false
          ],
          [
            `<button>${tr('GENERAL_CANCEL')}</button>`,
            (instance, toast) => {
              instance.hide({ transitionOut: 'fadeOut' }, toast, 'cancel');
              // Perform no action.
              resolve(null);
            },
            false
          ]
        ]
      });
    } else {
      concatted = concatted + content;
      resolve({ content: concatted });
    }
  });
};

const SuggestionEditorWidget = (props: SuggestionEditorProps) => {
  const { t } = useTranslation();
  const channelReplyTabIndexAndName = matchReplyChannelTabIndexAndName(
    props.replyTabIndexList,
    props.channels,
    props.channel
  );

  const handleSuggestionReply = async () => {
    const originalContent = await findTheCorrectDraftOfSuggestion(
      channelReplyTabIndexAndName.name,
      props.taskId,
      props.drafts
    );
    const newContents = await formatSuggestionToDraft(props.comment, originalContent, t);
    if (newContents !== null) {
      props.changeDraftContents(newContents, props.taskId, channelReplyTabIndexAndName.name, props.channel);
    }
  };

  return (
    <div style={{ maxHeight: '30px', whiteSpace: 'nowrap', width: '100%' }}>
      <Button icon={true} size="tiny" basic={true} labelPosition="left" onClick={handleSuggestionReply}>
        <Icon name="reply" />
        {t('BOT_SUGGESTION')}
      </Button>
    </div>
  );
};

export default React.memo(SuggestionEditorWidget);
