import { faHandsHelping } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Comment, Popup } from 'semantic-ui-react';

import type { FC } from 'react';

import { getSelectedChannelTab } from '../CommentIconContent/ChannelType';
import CommentHeader from '../generic/CommentHeader/CommentHeader';
import CommentTextEncapsulated from './CommentTextEncapsulated';
import { updateTicketTabState } from 'src/actions/draftActions';
import { DATE_TIME_FORMAT, getPrettyDate } from 'src/Utilities/dates';
import { parseContent } from 'src/Utilities/parseUtils';
import { onResponseTemplatePrompt } from 'src/Utilities/replyContent';
import { removeHTMLTags, sanitizeHTML } from 'src/Utilities/sanitize';
import { nonHTMLChannels } from 'src/Utilities/templates';

import type { Draft } from 'src/reducers/draftsReducer';
import type { State } from 'src/types/initialState';
import type { Comment as CommentType } from 'src/types/Ticket';

interface HelperCommentProps {
  comment: CommentType;
  compact?: boolean;
}

const HelperComment: FC<HelperCommentProps> = ({ comment, compact }) => {
  const activeTicketTab = useSelector((state: State) => state.activeTicketTab);
  const draft = useSelector((state: State) => (activeTicketTab ? state.drafts[activeTicketTab] : undefined)) as
    | Draft
    | undefined;
  const detailedTickets = useSelector((state: State) => state.detailedTickets);
  const selectedChannelTab = getSelectedChannelTab(activeTicketTab, detailedTickets, draft);
  const draftContent = (selectedChannelTab && draft?.[selectedChannelTab]?.content) ?? '';

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const useForReply = useCallback(async () => {
    let newContent: string = parseContent(comment.content ?? '', [], false);

    if (draftContent) {
      const update = await onResponseTemplatePrompt({
        update: { content: newContent },
        originalContent: draftContent,
        insertAtCursor: false,
        tr: t
      });

      if (update?.content) {
        newContent = update.content;
      }
    }

    if (activeTicketTab && selectedChannelTab) {
      newContent = nonHTMLChannels.includes(selectedChannelTab) ? removeHTMLTags(newContent) : sanitizeHTML(newContent);
      dispatch(updateTicketTabState(activeTicketTab, { [selectedChannelTab]: { content: newContent } }));
    }
  }, [draftContent, selectedChannelTab, activeTicketTab]);

  return (
    <Comment>
      <CommentHeader
        avatar={
          <span
            className="avatar"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
              width: '3em',
              height: '3em',
              backgroundColor: '#14375a',
              borderRadius: '5px'
            }}
          >
            <FontAwesomeIcon icon={faHandsHelping} color="#fff" size="xl" style={{ alignSelf: 'center' }} />
          </span>
        }
        name={t('widgets.chatGPT.author')}
        date={getPrettyDate(comment.created, { format: DATE_TIME_FORMAT })}
        compact={compact}
      />

      {comment.content && <CommentTextEncapsulated comment={comment.content} />}

      <Comment.Action style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
        <Popup trigger={<Button icon="reply" onClick={useForReply} />} content={t('USE_FOR_REPLY')} />
      </Comment.Action>
    </Comment>
  );
};

export default HelperComment;
