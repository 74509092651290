import type { CSSProperties } from 'react';

export const wrapper: CSSProperties = {
  backgroundColor: 'rgb(247, 247, 247)',
  borderTopLeftRadius: '5px',
  borderTopRightRadius: '5px',
  color: 'rgba(0, 0, 0, 0.95)'
};

export const title: CSSProperties = { fontSize: '14px', fontWeight: 700, padding: '0 10px' };

export const icon: CSSProperties = {
  position: 'absolute',
  fontSize: '10px',
  top: '5px',
  right: '5px',
  color: 'rgba(0, 0, 0, 0.95)'
};
