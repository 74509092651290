import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import type { TabFilter } from '@eeedo/types';
import type { FC } from 'react';
import type { ConnectedProps } from 'react-redux';

import { StaticTabs } from 'src/types/TicketList';
import { doneTicketsOrdering, filterTicketList, ticketOrdering } from 'src/Utilities/ticketList';

import type { State } from 'src/types/initialState';
import type { TicketListTicket } from 'src/types/Ticket';

import './SingleLineTicketView.css';

import SingleLineTicketViewContent from './SingleLineTicketViewContent';

import type { AccordionTab } from './SingleLineTicketViewContent';

interface SingleLineTicketViewProps extends ConnectedProps<typeof connector> {}

const SingleLineTicketView: FC<SingleLineTicketViewProps> = ({
  ticketListTab,
  categories,
  personalData,
  usersList
}) => {
  const filters = ticketListTab.filters ?? ({} as TabFilter);
  const { tickets: contents, sorting, direction } = ticketListTab;
  const orderedTickets = ticketOrdering(contents, sorting, direction, personalData.userCustomTicketOrdering);
  const filterArgs = {
    categories,
    personalData,
    filters,
    usersList
  };

  const todoTickets = useMemo(
    () =>
      filterTicketList({
        ...filterArgs,
        status: 'todo',
        tickets: orderedTickets.filter((ticket) => ticket.status === 'todo')
      }),
    [categories, personalData, filters, orderedTickets, usersList]
  );
  const doingTickets = useMemo(
    () =>
      filterTicketList({
        ...filterArgs,
        status: 'doing',
        tickets: orderedTickets.filter((ticket) => ticket.status === 'doing')
      }),
    [categories, personalData, filters, orderedTickets, usersList]
  );
  const doneTickets = useMemo(
    () =>
      filterTicketList({
        ...filterArgs,
        tickets: doneTicketsOrdering(ticketListTab.tickets.filter((ticket) => ticket.status === 'done')),
        status: 'done'
      }) as TicketListTicket[],
    [categories, personalData, filters, contents, usersList]
  );

  const accordionTabs: AccordionTab[] = useMemo(
    () => [
      { index: 0, status: 'todo', tickets: todoTickets },
      { index: 1, status: 'doing', tickets: doingTickets },
      { index: 2, status: 'done', tickets: doneTickets }
    ],
    [todoTickets, doingTickets, doneTickets]
  );

  return (
    <SingleLineTicketViewContent
      accordionTabs={accordionTabs}
      dropdownOptions={[
        { text: 'Deprecate', value: 'deprecate' },
        { text: 'Close as done', value: 'closeAsDone' }
      ]}
    />
  );
};

const connector = connect((state: State) => {
  const activeTab = Object.values(state.ticketListTabs).find((tab) => tab.activeTab);

  return {
    ticketListTab: state.ticketListTabs[activeTab?.id ?? StaticTabs.MAIN_VIEW],
    usersList: state.usersList.usersList,
    categories: state.categories,
    personalData: state.userData,
    ticketTypes: state.ticketTypes,
    channels: state.channels,
    priorities: state.ticketPriorities
  };
});

export default withRouter(connector(SingleLineTicketView));
