import { faLoader } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Icon, Message } from 'semantic-ui-react';

import type { UserWithProfile } from '@eeedo/types';
import type { FC } from 'react';

import Button from '../generic/Button/Button';
import * as styles from './InitialLoad.style';
import { logout, refreshToken } from 'src/actions/authActions';
import { fetchCaseImportTemplates } from 'src/actions/caseImportTemplateActions';
import { fetchCategories } from 'src/actions/categoryActions';
import { fetchChannelTypes } from 'src/actions/channelActions';
import { fetchChats } from 'src/actions/chatActions';
import { fetchEnvSettings, fetchFeatureFlags, fetchPhoneConfigurations } from 'src/actions/configActions';
import { fetchLinkLists } from 'src/actions/linkListActions';
import { fetchPriorities } from 'src/actions/priorityActions';
import { fetchResponseTemplates } from 'src/actions/responseTemplateActions';
import { fetchAutoSuggestions } from 'src/actions/suggestionActions';
import { fetchTags } from 'src/actions/tagActions';
import { fetchTicketPriorities, fetchTicketTypes, fetchTitleTemplates } from 'src/actions/ticketsActions';
import { fetchPersonalData, fetchRoles, fetchUsers } from 'src/actions/userActions';
import { fetchWebhooks } from 'src/actions/webhooksActions';
import { selectCurrentUser, selectIsCurrentUserAdmin } from 'src/selectors/rootStateSelectors';

import type { State } from 'src/types/initialState';

export type initialRequestsTypes =
  | 'users'
  | 'ticketTypes'
  | 'responseTemplates'
  | 'titleTemplates'
  | 'channelTypes'
  | 'linkLists'
  | 'personalData'
  | 'tags'
  | 'categories'
  | 'envSettings'
  | 'featureFlags'
  | 'roles'
  | 'caseImportTemplates'
  | 'priorities'
  | 'PhoneConfigurations'
  | 'chatStatuses'
  | 'PostIntegrationConfiguration'
  | 'autoSuggestions'
  | 'webhooks';

export interface InitialRequestState {
  type: initialRequestsTypes;
  isLoading: boolean;
  isCompleted: boolean;
  error: null | Error;
  adminRequired?: boolean;
}

interface InitialLoadProps {
  children: React.ReactNode;
}

const delay = (time: number) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve({});
    }, time);
  });

const InitialLoad: FC<InitialLoadProps> = ({ children }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showChildren, setShowChildren] = useState(false);

  const [stalled, setStalled] = useState(false);

  useEffect(() => {
    delay(5000).then(() => {
      setStalled(true);
    });
  }, []);

  const user = useSelector<State, UserWithProfile | undefined>(selectCurrentUser);
  const isAdmin = useSelector<State, boolean>(selectIsCurrentUserAdmin);
  const initialRequests = useSelector<State, InitialRequestState[]>((state) =>
    state.initialRequests.filter((req) => isAdmin || !req.adminRequired)
  );

  useEffect(() => {
    const accessTokenExpiration = localStorage.getItem('accessTokenExpiration');
    if (accessTokenExpiration) {
      dispatch(refreshToken(true));
    }

    // Dispatch all requests here!
    if (!user) {
      dispatch(fetchPersonalData());
      dispatch(fetchUsers());
    }

    if (user) {
      dispatch(fetchEnvSettings());
      dispatch(fetchFeatureFlags());
      dispatch(fetchTicketTypes());
      dispatch(fetchAutoSuggestions());
      dispatch(fetchChannelTypes());
      dispatch(fetchResponseTemplates());
      dispatch(fetchTitleTemplates());
      dispatch(fetchLinkLists());
      dispatch(fetchTags());
      dispatch(fetchCategories());
      dispatch(fetchChats());
      dispatch(fetchRoles());
      dispatch(fetchTicketPriorities());
      dispatch(fetchCaseImportTemplates());
      dispatch(fetchPriorities());
      dispatch(fetchPhoneConfigurations());

      if (isAdmin) {
        dispatch(fetchWebhooks());
      }
    }
  }, [user]);

  const loadOnClick = useCallback(() => setShowChildren(true), []);
  const logoutOnClick = useCallback(() => dispatch(logout({ type: 'manual' })), []);

  const sortStatuses = (a: InitialRequestState, b: InitialRequestState) => (a.type > b.type ? 1 : -1);
  const sortedRequests = useMemo(() => [...initialRequests].sort(sortStatuses), [initialRequests]);

  const completedRequests = initialRequests.filter((reqStatus) => reqStatus.isCompleted);
  const incompleteRequests = initialRequests.filter((reqStatus) => !reqStatus.isCompleted);
  const hasErrors = initialRequests.some((reqStatus) => reqStatus.error !== null);
  const failedRequests = sortedRequests.filter((request) => request.error);
  const allHasCompleted = completedRequests.length === initialRequests.length;

  if (showChildren === true || allHasCompleted) {
    return children;
  }

  return (
    <div style={styles.wrapper}>
      <div className="logo" style={styles.logo} />
      <div style={styles.header}>
        {t('LOADING')}... <FontAwesomeIcon icon={faLoader} spin />
      </div>

      {stalled && (
        <div style={styles.list}>
          {incompleteRequests.map((request) => (
            <div>
              {t('LOADING')} {t('INIT_LOAD_TITLE_' + request.type)}...
            </div>
          ))}
        </div>
      )}

      {hasErrors && (
        <div style={styles.messages}>
          <Message icon error size="small">
            <Icon name="info circle" />
            <Message.Content>
              <Message.Header>{t('INIT_LOAD_FAILED_TO_LOAD')}:</Message.Header>
              {failedRequests.map((request) => (
                <div dangerouslySetInnerHTML={{ __html: t('INIT_LOAD_TITLE_' + request.type) }} />
              ))}
            </Message.Content>
          </Message>
          <div style={styles.btnContainer}>
            <Button type="error" content={t('INIT_LOAD_CONTINUE_ANYWAY')} onClick={loadOnClick} />
            <Button
              type="primary"
              content={t('MAIN_TOPBAR_LOGOUT')}
              iconLeft={<Icon name="log out" />}
              onClick={logoutOnClick}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(InitialLoad);
