import fileDownload from 'js-file-download';
import React, { useEffect } from 'react';
import { Modal } from 'semantic-ui-react';

import type { FC } from 'react';

import ExportModalForm from './ExportModalForm';
import ExportModalList from './ExportModalList';
import ExportModalPreview from './ExportModalPreview';
import { ExportApi } from 'src/api/ExportApi';

import type { ExportJob, ExportTemplate } from 'src/types/Export';
import type { TicketListTab } from 'src/types/TicketList';

const REFRESH_INTERVAL = 2000;

interface ExportSelectorProps {
  handleClose: () => void;
  isOpen: boolean;
  ticketListTabs: TicketListTab[];
}

const ExportModal: FC<ExportSelectorProps> = ({ isOpen, ticketListTabs, handleClose }) => {
  const [templates, setTemplates] = React.useState<ExportTemplate[]>([]);
  const [jobs, setJobs] = React.useState<ExportJob[]>([]);
  const [previewFileId, setPreviewFileId] = React.useState<number | null>(null);

  useEffect(() => {
    fetchExportDataTemplates();
  }, []);

  useEffect(() => {
    fetchJobs();
    const intervalId = setInterval(fetchJobs, REFRESH_INTERVAL);
    return () => clearInterval(intervalId);
  }, []);

  const fetchJobs = async () => {
    try {
      const { data } = await ExportApi.getExportJobs();
      setJobs(data);
    } catch (error) {
      console.error('Failed to fetch export jobs', error);
    }
  };

  const fetchExportDataTemplates = async () => {
    try {
      const { data } = await ExportApi.getExportDataTemplates();
      setTemplates(data);
    } catch (error) {
      console.error('Failed to fetch export data templates', error);
    }
  };

  const download = async (fileId: number, fileName?: string) => {
    const { data } = await ExportApi.getFile(fileId);
    fileDownload(data, fileName || 'export.xlsx');
  };

  return (
    <Modal closeIcon open={isOpen} onClose={() => handleClose()}>
      <ExportModalForm refreshJobs={fetchJobs} templates={templates} ticketListTabs={ticketListTabs} />

      <ExportModalList jobs={jobs} onDownload={download} onPreview={setPreviewFileId} templates={templates} />

      {previewFileId && <ExportModalPreview fileId={previewFileId} onClose={() => setPreviewFileId(null)} />}
    </Modal>
  );
};

export default ExportModal;
