import { faClose } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { MenuItem } from 'semantic-ui-react';

import type { FC } from 'react';

import * as styles from './TicketListTab.style';
import { activateTicketlistTab } from 'src/actions/CaseListActions';
import { closeTicketlistTab } from 'src/actions/ticketListTabActionsRTK';
import { StaticTabs } from 'src/types/TicketList';

import type { TicketListTab as ITicketListTab } from 'src/types/TicketList';

interface TicketListTabProps {
  tab: ITicketListTab;
  activeTabId: string;
}

const TicketListTab: FC<TicketListTabProps> = ({ tab, activeTabId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <MenuItem
      name={t(tab.title)}
      style={styles.wrapper}
      active={activeTabId === tab.id}
      content={
        <>
          <div style={styles.title}>{t(tab.title)}</div>
          <FontAwesomeIcon
            icon={faClose}
            style={styles.icon}
            onClick={() => {
              dispatch(activateTicketlistTab(StaticTabs.MAIN_VIEW));
              dispatch(closeTicketlistTab(tab.id));
            }}
          />
        </>
      }
      onClick={() => {
        dispatch(activateTicketlistTab(tab.id));
      }}
    />
  );
};

export default React.memo(TicketListTab);
