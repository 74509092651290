import { t } from 'i18next';
import React from 'react';
import { useSelector } from 'react-redux';

import type { TFunction } from 'i18next';
import type { SemanticICONS } from 'semantic-ui-react';

import TopBarStatusButtons from 'src/Components/Case/TopBar/TopBarStatusButtons';
import Widgets from 'src/Components/Case/Widget/Widgets';
import ErrorBoundary from 'src/ErrorBoundary';
import type { State } from 'src/types/initialState';

const taskStatusOptions: (
  t: TFunction,
  pathname: string
) => {
  text: string;
  icon: SemanticICONS;
  value: string;
}[] = (t, pathname) =>
  pathname.startsWith('/infopage') || pathname.startsWith('/list/infopage')
    ? [
        {
          text: t('INFOPAGE_STATUS_DRAFT'),
          icon: 'write',
          value: 'draft'
        },
        {
          value: 'inReview',
          icon: 'magnify',
          text: t('INFOPAGE_STATUS_INREVIEW')
        },
        {
          value: 'waitingToBePublished',
          icon: 'wait',
          text: t('INFOPAGE_STATUS_WAITING')
        },
        {
          value: 'published',
          icon: 'book',
          text: t('INFOPAGE_STATUS_PUBLISHED')
        },
        {
          value: 'waste',
          icon: 'trash',
          text: t('INFOPAGE_STATUS_WASTE')
        }
      ]
    : [
        {
          text: t('CASE_STATUS_TODO'),
          icon: 'exclamation',
          value: 'todo'
        },
        {
          text: t('CASE_STATUS_DOING'),
          icon: 'clock',
          value: 'doing'
        },
        {
          text: t('CASE_STATUS_DONE'),
          icon: 'check',
          value: 'done'
        }
      ];

export const DesktopLayoutRight = () => {
  const pathname = useSelector((state: State) => state.router.location.pathname || '');
  return (
    <div id="topelement">
      <div className="case-right-side">
        <TopBarStatusButtons contentType="task" statusOptions={taskStatusOptions(t, pathname)} />

        <div className="case-widgets">
          <ErrorBoundary>
            <Widgets />
          </ErrorBoundary>
        </div>
      </div>
    </div>
  );
};
