import React, { useCallback } from 'react';

import type { ComponentProps, CSSProperties, FC } from 'react';
import type { SemanticICONS } from 'semantic-ui-react';

import Dropdown from 'src/Components/generic/Dropdown/Dropdown';

import type { StatusTypes } from 'src/types/Ticket';

type OnChange = NonNullable<ComponentProps<typeof Dropdown>['onChange']>;

interface TicketStatusDropdownProps {
  value: string;
  statusOptions: {
    text: string;
    icon: SemanticICONS;
    value: string;
  }[];
  loading?: boolean;
  disabled?: boolean;
  placeholder?: string;
  style?: CSSProperties;

  handleStatusChange: (value: string) => void;
}

const TicketStatusDropdown: FC<TicketStatusDropdownProps> = ({
  value,
  style,
  loading,
  disabled,
  placeholder,
  statusOptions,
  handleStatusChange
}) => {
  const onChange = useCallback<OnChange>((_, data) => {
    handleStatusChange(data.value!.toString() as StatusTypes);
  }, []);

  return (
    <Dropdown
      id="ticketStatusDropdown"
      search
      selection
      style={style}
      value={value}
      loading={loading}
      disabled={disabled}
      selectOnBlur={false}
      options={statusOptions}
      placeholder={placeholder}
      onChange={onChange}
    />
  );
};

export default TicketStatusDropdown;
