import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Container, Dropdown, Form, Header, Icon, Modal } from 'semantic-ui-react';

import type { FC } from 'react';

import { ExportApi } from 'src/api/ExportApi';
import { StaticTabs } from 'src/types/TicketList';

import type { ExportTemplate } from 'src/types/Export';
import type { TicketListTab } from 'src/types/TicketList';

interface Props {
  refreshJobs: () => void;
  templates: ExportTemplate[];
  ticketListTabs: TicketListTab[];
}

const ExportModalForm: FC<Props> = ({ ticketListTabs, templates, refreshJobs }) => {
  const { t } = useTranslation();
  const [template, setTemplate] = React.useState<number | undefined>();
  const [ticketListTabId, setTicketListTabIdId] = React.useState<string | undefined>();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const isDisabled = isLoading || !template || !ticketListTabId;

  const generateExport = async () => {
    const ticketListTab = ticketListTabs.find(({ id }) => id === ticketListTabId);
    const ids = (ticketListTab?.tickets || []).map((ticketListTicket) =>
      parseInt(ticketListTicket.id.substring(3), 10)
    );

    if (!ids.length || !template) {
      return;
    }

    try {
      setIsLoading(true);
      await ExportApi.addExportJob(ids, template);
      refreshJobs();
    } catch (error) {
      console.error('Failed to fetch export data', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <React.Fragment>
      <Header icon="external share" content={t('export.modal.title')} />

      <Modal.Content>
        <Form>
          <Form.Group widths="equal">
            <Form.Field>
              <label>{t('export.modal.template.label')}</label>
              <Dropdown
                noResultsMessage={t('GENERAL_SEARCH_NO_RESULTS')}
                onChange={(event, { value }) => setTemplate(value as number)}
                placeholder={t('DROPDOWN_CHOOSE')}
                search
                selectOnBlur={false}
                selection
                value={template}
                options={templates.map((template) => ({
                  text: template.name,
                  value: template.id
                }))}
              />
            </Form.Field>
            <Form.Field>
              <label>{t('export.modal.tabs.label')}</label>
              <Dropdown
                noResultsMessage={t('GENERAL_SEARCH_NO_RESULTS')}
                onChange={(event, data) => setTicketListTabIdId(data.value?.toString())}
                placeholder={t('DROPDOWN_CHOOSE')}
                search
                selectOnBlur={false}
                selection
                value={ticketListTabId}
                options={ticketListTabs
                  .filter(({ title }) => title !== StaticTabs.MAIN_VIEW)
                  .map((ticketListTab) => ({
                    text: `${t(ticketListTab.title) || ticketListTab.id} (${ticketListTab.tickets?.length || 0})`,
                    value: ticketListTab.id
                  }))}
              />
            </Form.Field>
          </Form.Group>
        </Form>

        <Container textAlign="right">
          <Button icon loading={isLoading} disabled={isDisabled} primary onClick={generateExport}>
            <Icon name="external share" />
            {t('export.modal.actions.generate')}
          </Button>
        </Container>
      </Modal.Content>
    </React.Fragment>
  );
};

export default ExportModalForm;
