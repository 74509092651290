import { faBug, faFileInvoiceDollar, faPhoneArrowDownLeft, faUser } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Divider, Popup } from 'semantic-ui-react';

import type { IconDefinition } from '@fortawesome/pro-regular-svg-icons';

import { useFocusMode } from 'src/Components/FocusMode/FocusModeContext';

const _Menu = () => {
  const filters: { id: number; icon: IconDefinition; name: string; counts: number[] }[] = [
    { id: 1, icon: faPhoneArrowDownLeft, name: 'Support tickets', counts: [71, 4, 171] },
    { id: 2, icon: faUser, name: 'My tickets', counts: [31, 1, 17] },
    { id: 3, icon: faFileInvoiceDollar, name: 'Invoices', counts: [20, 0, 100] },
    { id: 4, icon: faBug, name: 'Bug tickets', counts: [9, 3, 148] }
  ];

  const [activeIds, setActiveIds] = useState<number[]>([]);

  const [search] = useState<string>('');

  // const debounceHandleSearch = debounce((event: React.ChangeEvent<HTMLInputElement>) => {
  //   setSearch(event.target.value);
  // }, 250);

  const handleToggle = (id: number) => {
    const indexOfId = activeIds.indexOf(id);
    if (indexOfId === -1) {
      setActiveIds([...activeIds, id]);
    } else {
      activeIds.splice(indexOfId, 1);
      setActiveIds([...activeIds]);
    }
  };

  const matchingFilters = filters.filter((x) => x.name.toLowerCase().includes(search));

  return (
    <div className="list-of-filters-main">
      <div style={{ marginBottom: '10px' }}>Filters</div>
      <div className="list-of-filters-list">
        {matchingFilters.length === 0 && <span>No matching filters</span>}
        {matchingFilters.map((filter) => (
          <Popup
            trigger={
              <div
                className={`list-of-filters-list-item ${activeIds.includes(filter.id) && 'active'}`}
                onClick={() => {
                  handleToggle(filter.id);
                }}
              >
                <div className="list-of-filters-list-item-name">
                  <FontAwesomeIcon icon={filter.icon} style={{ color: '#565656' }} size='lg' />
                  {filter.name}
                </div>
                <div className="list-of-filters-list-item-count">{filter.counts[0]}</div>
              </div>
            }
            content={
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div>
                  <FontAwesomeIcon icon={filter.icon} style={{ marginRight: '8px' }} />
                  {filter.name}
                </div>
                <Divider />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '5px' }}>
                    <div className="list-of-filters-list-item-name">Todo</div>
                    <div className="list-of-filters-list-item-count">{filter.counts[0]}</div>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '5px' }}>
                    <div className="list-of-filters-list-item-name">Doing</div>
                    <div className="list-of-filters-list-item-count">{filter.counts[1]}</div>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '5px' }}>
                    <div className="list-of-filters-list-item-name">Done</div>
                    <div className="list-of-filters-list-item-count">{filter.counts[2]}</div>
                  </div>
                </div>
              </div>
            }
            position="right center"
          />
        ))}
      </div>
    </div>
  );
};

export const ListOfPresetFilters = () => {
  const [isVisible, setIsVisible] = useState(true);
  const { isFocusMode } = useFocusMode();

  useEffect(() => {
    setIsVisible(!isFocusMode);
  }, [isFocusMode]);

  return (
    <div className={`layout-desktop-filters ${isVisible && 'active'}`}>
      {isVisible && <_Menu />}
      {!isVisible && <div style={{ height: '20px', width: '30px' }}></div>}
    </div>
  );
};
