import moment from 'moment';
import React, { useState } from 'react';
import { SingleDatePicker } from 'react-dates';
import { Table } from 'semantic-ui-react';

import type { FC } from 'react';
import type { SemanticWIDTHS } from 'semantic-ui-react';

import { DATE_FORMAT } from 'src/Utilities/dates';

import type { InfoFieldProps } from './InfoFieldProps';

interface InfoDatepickerProps extends Pick<InfoFieldProps, 'field' | 'isMobile' | 'onSaveData'> {
  selectDateMessage: string;
  disabled: boolean;
  fieldValue?: string;
  width?: SemanticWIDTHS;
}

const InfoDatepicker: FC<InfoDatepickerProps> = ({
  field,
  isMobile,
  disabled,
  fieldValue,
  width = 10,
  selectDateMessage,
  onSaveData
}) => {
  const [date, setDate] = useState(fieldValue ? moment(fieldValue) : null);
  const [focused, setFocused] = useState(false);

  return (
    <Table.Cell
      className={'InfoDatePickerWrapper'}
      width={width}
      onClick={() => {
        setFocused(true);
      }}
    >
      <SingleDatePicker
        id={'Päivämäärä'}
        onDateChange={(date: moment.Moment | null) => {
          if (isMobile) {
            setDate(date);
          }
          setFocused(false);
          onSaveData?.(field, date);
        }}
        displayFormat={DATE_FORMAT}
        small={true}
        numberOfMonths={1}
        placeholder=""
        focused={focused}
        date={date}
        disabled={disabled}
        block={false}
        isOutsideRange={() => false}
        withPortal={isMobile}
        openDirection="up"
        hideKeyboardShortcutsPanel={true}
        inputIconPosition={!isMobile ? 'after' : undefined}
        onFocusChange={({ focused }) => {
          if (!isMobile) {
            setFocused(!!focused);
          }
        }}
        customCloseIcon={!isMobile ? <span>x</span> : undefined}
        {...field.props}
      />

      {!moment(fieldValue).isValid() && <p style={{ color: 'red' }}>{selectDateMessage}</p>}
    </Table.Cell>
  );
};

export default React.memo(InfoDatepicker);
