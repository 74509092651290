import React from 'react';

import type { FC } from 'react';

import Survey from './Survey';

import type { SurveyAnswer } from 'src/types/Survey';

interface SurveyListProps {
  taskId: string;
  surveys: Array<SurveyAnswer>;
  getAndCopyLink: (surveyId: number) => void;
}

const SurveyList: FC<SurveyListProps> = ({ surveys, getAndCopyLink }) => (
  <div>
    {surveys.map((s) => (
      <Survey key={s.id} survey={s} complete={s.answeredAt !== null} getAndCopyLink={getAndCopyLink} />
    ))}
  </div>
);

export default SurveyList;
