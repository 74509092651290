import type { CSSProperties } from 'react';

type ColorFunction = (colors: { bgColor: string; fontColor: string }) => CSSProperties;

export const item: CSSProperties = { height: '100%' };

export const status: (thresholdColor: string) => CSSProperties = (thresholdColor) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  height: '100%',
  width: '6px',
  backgroundColor: thresholdColor
});

export const allTagsLabel: ColorFunction = ({ bgColor, fontColor }) => ({
  backgroundColor: bgColor,
  color: fontColor,
  margin: '1.5px'
});

export const title: CSSProperties = {
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  width: '70%',
  display: 'inline-block',
  verticalAlign: 'bottom'
};

export const metaLabel: ColorFunction = ({ bgColor, fontColor }) => ({
  backgroundColor: bgColor,
  color: fontColor
});

export const metaWrapper: CSSProperties = { display: 'inline-flex', maxHeight: '26px' };

export const metaButtonWrapper: CSSProperties = { display: 'flex' };

export const metaButton: CSSProperties = {
  height: '26px',
  width: '27px'
};
