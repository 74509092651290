import React from 'react';
import { Translation } from 'react-i18next';
import { connect } from 'react-redux';
import { Button, Comment, Icon, Loader } from 'semantic-ui-react';

import type { UserWithProfile } from '@eeedo/types';
import type { ConnectedProps } from 'react-redux';

import CommentTextEncapsulated, { EXPAND_COMMENT_HEIGHT, EXPAND_COMMENT_LENGTH } from './CommentTextEncapsulated';

import type { State } from 'src/types/initialState';
import type { Attachment, Comment as TicketComment } from 'src/types/Ticket';

interface CommentTextProps extends CommentTextReduxProps {
  parsedContent?: string | null;
  isChatComment: boolean;
  isCommentExpanded: boolean;
  isLastComment: boolean;
  attachments: Attachment[];
  botButtonClickedState?: TicketComment;
  metaData?: TicketComment['metaData'];
  type: TicketComment['type'];
  users: UserWithProfile[];
  toggleExpand(): void;
  isCommentPreview?: boolean;
}

const CommentText = ({
  parsedContent,
  isChatComment,
  isCommentExpanded,
  isLastComment,
  botButtonClickedState,
  metaData,
  toggleExpand,
  isCommentPreview
}: CommentTextProps) => {
  const [commentTextHeight, setCommentTextHeight] = React.useState(0);
  const isCommentTooLong =
    !isCommentPreview &&
    parsedContent &&
    !isLastComment &&
    (commentTextHeight > EXPAND_COMMENT_HEIGHT || parsedContent.length > EXPAND_COMMENT_LENGTH);

  return (
    <Translation>
      {(t) => (
        <Comment.Text style={{ whiteSpace: 'pre-line', overflowWrap: 'break-word' }}>
          {parsedContent === undefined && (
            <div className="Comment-Loader">
              <Loader active inline />
            </div>
          )}

          {isChatComment && (
            <div className={'Comment-Content images-max-w-95'}>
              <em>{parsedContent}</em>
            </div>
          )}
          {!isChatComment && !botButtonClickedState && (
            <div>
              <div
                data-test="commentTextEncapsulated"
                className={`${!isCommentExpanded && isCommentTooLong ? 'collapsedComment' : ''}`}
              >
                <CommentTextEncapsulated
                  comment={parsedContent}
                  setCommentTextHeight={setCommentTextHeight}
                  isCommentExpanded={isCommentExpanded || isLastComment}
                />
              </div>

              {isCommentTooLong && (
                <div className="expandCommentIcon">
                  <Icon name={!isCommentExpanded ? 'chevron down' : 'chevron up'} onClick={toggleExpand} />
                </div>
              )}
            </div>
          )}

          {!!metaData?.buttons && (
            <div className="Comment-Content images-max-w-95" style={{ marginTop: '6px' }}>
              {metaData.buttons.map((buttonData) => (
                <Button size="tiny" disabled={true}>
                  {buttonData.label}
                </Button>
              ))}
            </div>
          )}

          {!!botButtonClickedState && (
            <div className="Comment-Content images-max-w-95" style={{ marginTop: '6px' }}>
              <p style={{ marginBottom: '5px' }}>
                {`${t('AUTOMATIC_COMMENT_BOT_CLICKED_BUTTON')} `}
                {
                  botButtonClickedState.metaData?.buttons?.find(
                    (button) => button.buttonState === metaData?.userClickedButton
                  )?.label
                }
              </p>

              {botButtonClickedState.metaData?.buttons?.map((buttonData) => (
                <Button
                  size="tiny"
                  primary={buttonData.buttonState === metaData?.userClickedButton}
                  disabled={buttonData.buttonState !== metaData?.userClickedButton}
                  style={{
                    pointerEvents: 'none'
                  }}
                >
                  {buttonData.label}
                </Button>
              ))}
            </div>
          )}
        </Comment.Text>
      )}
    </Translation>
  );
};

const connector = connect((state: State) => ({
  users: state.usersList.usersList
}));

type CommentTextReduxProps = ConnectedProps<typeof connector>;

export default connector(CommentText);
