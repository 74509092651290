import type { CSSProperties } from 'react';

export const wrapper: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  fontSize: '14px',
  fontWeight: 700,
  cursor: 'pointer'
};

export const icon: CSSProperties = { cursor: 'pointer', marginLeft: '10px', fontSize: '10px', marginRight: '10px' };
