import { DefaultTicketsOrdering, FilterPresetView } from '@eeedo/types';
import { goBack, push } from 'connected-react-router';
import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';

import type { FC } from 'react';
import type { ConnectedProps } from 'react-redux';

import Button from '../generic/Button/Button';
import Search from './Search/Search';
import Tabs from './Tabs/Tabs';
import TicketLists from './TicketLists';
import { fetchFilterPresets, resetFilterPresets, setActiveFilterPreset } from 'src/actions/filterPresetsActions';
import { setInfopagelistFilter } from 'src/actions/infoPagesActionsRTK';
import { setTicketlistFilter, setTicketlistSorting } from 'src/actions/ticketListTabActionsRTK';
import FeatureFlags from 'src/api/FeatureFlags';
import TicketListFilter from 'src/containers/TicketListFilterContainer';
import ErrorBoundary from 'src/ErrorBoundary';
import { setToolsActiveTab } from 'src/reducers/ticketListActiveTabsReducer';
import { SINGLE_LINE_VIEW_INFOPAGES_PATH, SINGLE_LINE_VIEW_TICKETS_PATH } from 'src/Utilities/constants';

import type { IFilterItem } from 'src/types/Filter';
import type { State } from 'src/types/initialState';
import type { MenuTab } from 'src/types/MenuTab';
import type { ContentTypesFields } from 'src/types/Ticket';
import type { TicketListTab as TicketTab } from 'src/types/TicketList';

const [defaultDirection, defaultSorting] = DefaultTicketsOrdering;

interface OwnProps {
  contentType: ContentTypesFields;
  noDefaultDates?: boolean;
}

interface Props extends TicketListTabReduxProps, OwnProps {}

const TicketListTab: FC<Props> = (props) => {
  const dispatch = useDispatch();

  const { pathname } = props;
  const isSingleLineView = [SINGLE_LINE_VIEW_INFOPAGES_PATH, SINGLE_LINE_VIEW_TICKETS_PATH].includes(pathname);

  // Check undefined values in filters objects
  const isFilterOn =
    !Object.keys(props.filters).every((k) => !props.filters[k]) ||
    (props.sorting && props.sorting !== defaultSorting) ||
    (props.direction && props.direction !== defaultDirection);

  useEffect(() => {
    if (isSingleLineView) {
      if (!props.activeToolTab) {
        dispatch(setToolsActiveTab('filter'));
      }
    }
  }, [pathname]);

  useEffect(() => {
    const { pathname, contentType } = props;
    // this component never seems to unmount so need to follow some state change
    if (pathname === '/' || ['/case', '/infopage'].some((path) => pathname.startsWith(path))) {
      dispatch(
        fetchFilterPresets({
          view: contentType === 'infopages' ? FilterPresetView.infopage : FilterPresetView.main
        })
      );
    }
  }, [props.pathname, props.contentType]);

  useEffect(() => {
    return () => {
      props.resetFilterPresets();
    };
  }, []);

  useEffect(() => {
    if (isFilterOn) {
      return;
    }

    const defaultFilter = props.filterPresets.find((p) => p.id === props.defaultFilterPreset);
    if (defaultFilter) {
      if (!props.activeTabId) {
        return;
      }

      dispatch(setActiveFilterPreset({ filterPresetId: defaultFilter.id }));

      Object.keys(defaultFilter?.filters).forEach((filterKey) => {
        props.setFilter({
          id: props.activeTabId!,
          value: defaultFilter?.filters[filterKey],
          parameter: filterKey as any
        });
      });

      props.setSorting?.(defaultFilter.sorting as any, defaultFilter.direction);
    }
  }, [props.filterPresets, props.defaultFilterPreset]);

  return (
    <React.Fragment>
      <div style={{ paddingBottom: '10px', height: '45px', display: 'flex', justifyContent: 'space-between' }}>
        <Tabs />
        <Button
          type="normal"
          style={{ marginRight: '20px' }}
          onClick={() => {
            const action = isSingleLineView
              ? goBack()
              : push(
                  pathname.startsWith('/infopage') ? SINGLE_LINE_VIEW_INFOPAGES_PATH : SINGLE_LINE_VIEW_TICKETS_PATH
                );
            dispatch(action);
          }}
          content={isSingleLineView ? 'List' : 'Line'}
        />
      </div>
      <ErrorBoundary>
        {!isSingleLineView && !props.activeToolTab && <TicketLists contentType={props.contentType} />}
        {props.activeToolTab === 'filter' && FeatureFlags.isFlagOn('ENABLE_TICKET_LIST_FILTERING') && (
          <TicketListFilter contentType={props.contentType} />
        )}
        {props.activeToolTab === 'search' && <Search contentType={props.contentType} closePane={() => {}} />}
      </ErrorBoundary>
    </React.Fragment>
  );
};

const mapStateToProps = (state: State, ownProps: OwnProps) => {
  const tabs: MenuTab[] =
    ownProps.contentType === 'tickets' ? Object.values(state.ticketListTabs) : [...state.infoPageListTabs.values()];
  const activeTab = tabs.find((tab) => tab.activeTab);

  return {
    activeTabId: activeTab?.id,
    filters: activeTab?.filters ?? {},
    sorting: (activeTab as TicketTab)?.sorting,
    direction: (activeTab as TicketTab)?.direction,
    filterPresets: state.filterPresets.filterPresets,
    defaultFilterPreset: state.userData.userPreferences.defaultFilterPreset,
    pathname: state.router.location.pathname,
    activeToolTab: state.ticketListActiveTabs.tools
  };
};

const mapDispatchToProps = (dispatch: any, ownProps: OwnProps) => ({
  setFilter: (item: IFilterItem) => {
    dispatch((ownProps.contentType === 'infopages' ? setInfopagelistFilter : setTicketlistFilter)(item));
  },
  setSorting:
    ownProps.contentType === 'tickets'
      ? (...args: Parameters<typeof setTicketlistSorting>) => {
          dispatch(setTicketlistSorting(...args));
        }
      : undefined,
  resetFilterPresets: () => {
    dispatch(resetFilterPresets());
  }
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type TicketListTabReduxProps = ConnectedProps<typeof connector>;

export default connector(TicketListTab);
