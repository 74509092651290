import type { CSSProperties } from 'react';

export const wrapper: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100vw',
  height: '100vh',
  backgroundColor: 'rgba(127, 86, 218, 1)',
  color: 'white'
};

export const logo: CSSProperties = { width: '360px', height: '80px' };

export const header: CSSProperties = {
  marginBottom: '50px',
  marginTop: '20px',
  fontSize: '24px',
  fontWeight: 700,
  color: 'white'
};

export const list: CSSProperties = { fontSize: '16px', padding: '15px', display: 'flex', flexDirection: 'column' };

export const btnContainer: CSSProperties = { display: 'flex', justifyContent: 'space-between' };

export const messages: CSSProperties = { marginTop: '20px', width: '500px' };
