import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input, TextArea } from 'semantic-ui-react';

import type { TextAreaProps } from 'semantic-ui-react';

import MultiSelectInput from '../../MultiSelectInput/MultiSelectInput';

import type { Option } from '../../MultiSelectInput/MultiSelectInput';
import type { ReplySalesforceState } from '../ReplySalesforce';
import type { SalesforceFormPayload } from 'src/types/Salesforce';

interface IProps {
  validationErrors: { [key: string]: any };
  payload: SalesforceFormPayload;

  handleSetState: (fields: Partial<ReplySalesforceState['payload']>) => void;
}

const SalesforcePhoneForm: React.FC<IProps> = ({ payload, validationErrors, handleSetState }) => {
  const { t } = useTranslation();
  const arrayPhoneErrorKey = Object.keys(validationErrors).find((key) => key.match(/Phones\[([0-9])*\].value/));

  const onMultiSelectInputChange = useCallback((value: Option[]) => {
    handleSetState({
      Phones: value
    });
  }, []);
  const onTextAreaChange = useCallback((_event: React.SyntheticEvent, data: TextAreaProps) => {
    handleSetState({
      Description: data.value as string
    });
  }, []);

  return (
    <>
      <Form.Field
        required
        error={
          validationErrors.Phones
            ? {
                content: validationErrors.Phones,
                pointing: 'above'
              }
            : arrayPhoneErrorKey && {
                content: validationErrors[arrayPhoneErrorKey],
                pointing: 'above'
              }
        }
        control={Input}
        label={t('GENERAL_PHONES')}
      >
        <MultiSelectInput
          value={payload.Phones || []}
          placeholder={t('salesforce_reply.list_emails')}
          handleValueChange={onMultiSelectInputChange}
        />
      </Form.Field>

      <Form.Field
        required
        error={
          validationErrors.Description && {
            content: validationErrors.Description,
            pointing: 'above'
          }
        }
        control={TextArea}
        value={payload.Description}
        label={t('GENERAL_CONTENT')}
        onChange={onTextAreaChange}
      />
    </>
  );
};

export default React.memo(SalesforcePhoneForm);
