import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Label, Modal, Table } from 'semantic-ui-react';

import type { FC } from 'react';
import type { SemanticCOLORS } from 'semantic-ui-react';

import styles from './Export.module.css';
import { ExportJobStatus } from 'src/types/Export';

import type { ExportJob, ExportTemplate } from 'src/types/Export';

const StatusColors: { [key: string]: SemanticCOLORS } = {
  [ExportJobStatus.PENDING]: 'yellow',
  [ExportJobStatus.PROCESSING]: 'yellow',
  [ExportJobStatus.DONE]: 'green',
  [ExportJobStatus.ERROR]: 'red'
};

interface Props {
  jobs: ExportJob[];
  onDownload: (fileId: number, fileName?: string) => void;
  onPreview: (fileId: number) => void;
  templates: ExportTemplate[];
}

const ExportModalList: FC<Props> = ({ templates, jobs, onDownload, onPreview }) => {
  const { t } = useTranslation();

  const getTemplateName = (templateId: number) => {
    const template = templates.find(({ id }) => id === templateId);
    return template ? template.name : '';
  };

  if (!jobs.length) {
    return null;
  }

  return (
    <Modal.Actions className={styles.exportModalList}>
      <Table basic="very">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{t('export.modal.list.template_title')}</Table.HeaderCell>
            <Table.HeaderCell>{t('export.modal.list.status_title')}</Table.HeaderCell>
            <Table.HeaderCell style={{ width: '275px' }}>{t('export.modal.list.actions_title')}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {jobs.map((job) => (
            <Table.Row key={job.id}>
              <Table.Cell>
                {getTemplateName(job.templateId)} ({job.ticketIds.length} {t('export.modal.list.tickets')})
              </Table.Cell>
              <Table.Cell>
                <Label color={StatusColors[job.status]} content={t(`export.modal.list.status.${job.status}`)} />
              </Table.Cell>
              <Table.Cell>
                <Button
                  content={t('export.modal.actions.table')}
                  disabled={job.status !== ExportJobStatus.DONE || !job.fileId}
                  icon="table"
                  size="small"
                  onClick={() => job.fileId && onPreview(job.fileId)}
                />
                <Button
                  content={t('export.modal.actions.download')}
                  disabled={job.status !== ExportJobStatus.DONE || !job.fileId}
                  icon="download"
                  size="small"
                  onClick={() => job.fileId && onDownload(job.fileId, job.fileName)}
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Modal.Actions>
  );
};

export default ExportModalList;
