import { connect } from 'react-redux';

import ExportModal from '../Components/Export/ExportModal';
import type { State } from 'src/types/initialState';
import type { TicketListTab } from 'src/types/TicketList';

interface OwnProps {
  isOpen: boolean;

  handleClose: () => void;
}

interface ExportContainerStateProps {
  ticketListTabs: TicketListTab[];
}

interface OwnAndStateProps extends OwnProps, ExportContainerStateProps {}

const mapStateToProps = (state: State, ownProps: OwnProps): OwnAndStateProps => {
  return {
    ticketListTabs: Object.values(state.ticketListTabs),
    ...ownProps
  };
};

export default connect(mapStateToProps)(ExportModal);
