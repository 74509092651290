export const headerStyle: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  cursor: 'pointer',
  width: '100%'
};

export const contentStyle: React.CSSProperties = {
  transition: 'height 0.3s ease'
};
