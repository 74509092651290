import React from 'react';
import { useTranslation } from 'react-i18next';
import { Label } from 'semantic-ui-react';

import type ChannelType from '../CommentIconContent/ChannelType';
import type { ViewMode } from './VersionViewer';
import type { Counters, VersionControlClient } from 'src/api/VersionControl';

interface VersionCountersProps {
  id: number;
  channelId?: ChannelType;
  dataSource: VersionControlClient;

  onClick?: (name: ViewMode) => void;
}

const VersionCounters: React.FC<VersionCountersProps> = ({ id, channelId, onClick, dataSource }) => {
  const { t } = useTranslation();
  const [{ drafts, versions }, setCounters] = React.useState<Counters>(dataSource.getCounters(id, channelId));

  React.useEffect(() => {
    dataSource.setCounterNotify((recvid: number) => {
      if (id === recvid) {
        const counters = dataSource.getCounters(id, channelId);
        setCounters(counters);
      }
    });
    return () => {
      dataSource.setCounterNotify(null);
    };
  });

  const handleClick = (mode: ViewMode) => {
    onClick && onClick(mode);
  };

  if (!drafts && !versions) return null;

  return (
    <div key={`versions_${id}`} style={{ textAlign: 'right', margin: '6px 0' }}>
      {drafts > 0 && (
        <Label
          size="tiny"
          style={{ cursor: 'pointer' }}
          onClick={() => handleClick('drafts')}
          icon="pencil"
          content={`${t('DRAFTS')}: ${drafts}`}
        />
      )}
      {versions > 0 && (
        <Label
          size="tiny"
          style={{ cursor: 'pointer' }}
          onClick={() => handleClick('versions')}
          icon="tasks"
          content={`${t('PREVIOUS_VERSIONS')}: ${versions}`}
        />
      )}
    </div>
  );
};

export default VersionCounters;
