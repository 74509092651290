import React, { useCallback, useRef, useState } from 'react';
import { Popup } from 'semantic-ui-react';

import type { FC } from 'react';

import * as styles from './ArticleItemHeading.style';
import { useIsOverflow } from 'src/hooks/useIsOverflow';
import { highlightContentByInput } from 'src/Utilities/highlighter';

interface ArticleItemHeadingProps {
  title: string;
  term: string;
}

const ArticleItemHeading: FC<ArticleItemHeadingProps> = ({ title, term }) => {
  const [open, setOpen] = useState(false);
  const heading = useRef<HTMLHeadingElement>(null);
  const showPopup = useIsOverflow(heading);

  const onMouseOver = useCallback(() => {
    if (showPopup) setOpen(true);
  }, [showPopup]);
  const onMouseOut = useCallback(() => {
    if (showPopup) setOpen(false);
  }, [showPopup]);

  return (
    <Popup
      open={open}
      trigger={
        <h4
          ref={heading}
          style={styles.ellipsedHeading}
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}
          dangerouslySetInnerHTML={{ __html: highlightContentByInput({ content: title, input: term }) }}
        />
      }
    >
      <h4>{title}</h4>
    </Popup>
  );
};

export default React.memo(ArticleItemHeading);
