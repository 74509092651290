import React from 'react';
import { Menu } from 'semantic-ui-react';

import type { FC } from 'react';

interface Props {
  activeItem: string;
  items: { key: string; title: string }[];
  setActiveItem: (key: string) => void;
}

const TicketRelationsTabs: FC<Props> = ({ activeItem, items, setActiveItem }) => (
  <Menu tabular>
    {items.map((item) => (
      <Menu.Item
        active={activeItem === item.key}
        content={item.title}
        key={item.key}
        onClick={() => setActiveItem(item.key)}
        style={{ backgroundColor: '#f5f5f5' }}
      />
    ))}
  </Menu>
);

export default TicketRelationsTabs;
