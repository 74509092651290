import { connect } from 'react-redux';

import { removeCallRequest } from 'src/actions/phoneActions';
import ElisaRing from 'src/Components/PhoneServices/ElisaRing';
import { handleIncomingPhoneCallElisaRing } from 'src/handlers/handlePhoneCallElisaRing';

import type { State } from 'src/types/initialState';

const mapStateToProps = (state: State, ownProps: any) => {
  return {
    ...ownProps,
    callRequests: state.callRequests,
    userData: state.userData,
    phoneConfigurations: state.phoneConfigurations,
    ticketTypes: state.ticketTypes
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    removeCallRequest: (ticketId: number, phoneNumber: string) => {
      dispatch(
        removeCallRequest({
          ticketId,
          phoneNumber
        })
      );
    },
    handleIncomingPhoneCallElisaRing: (
      originalNumber: string,
      UID: string,
      ticketData: any,
      searchObject: any,
      serviceName: string | null
    ) => {
      return dispatch(handleIncomingPhoneCallElisaRing(originalNumber, UID, ticketData, searchObject, serviceName));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ElisaRing);
