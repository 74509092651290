import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Checkbox, Icon, Label, TableCell, TableRow } from 'semantic-ui-react';

import type { ComponentProps, FC } from 'react';
import type { ConnectedProps } from 'react-redux';
import type { SemanticICONS } from 'semantic-ui-react';

import * as styles from './SingleLineTicketItem.style';
import { getCallbackTime } from 'src/Components/Utilities/reschedule-callbakc-widget';
import WorkStatusImageContainer from 'src/containers/WorkStatusImageContainer';
import { channelIconsMap } from 'src/Utilities/constants';

import type { State } from 'src/types/initialState';
import type { TicketListTicket } from 'src/types/Ticket';

interface SingleLineTicketItemProps extends ConnectedProps<typeof connector> {
  ticket: TicketListTicket;
}

const SingleLineTicketItem: FC<SingleLineTicketItemProps> = ({ ticket, user, userData, priorities }) => {
  const { t } = useTranslation();
  const [checked, setChecked] = useState<boolean | undefined>(false);
  const priority = priorities.find((p) => p.value === ticket.priority);

  const onCheckboxChange = useCallback<NonNullable<ComponentProps<typeof Checkbox>['onChange']>>(
    (_, { checked: chk }) => {
      setChecked(chk);
    },
    []
  );
  const iconStyle = useMemo(() => ({ color: priority?.color }), [priority?.color]);

  return (
    <TableRow positive={checked}>
      <TableCell>
        <Checkbox checked={checked} onChange={onCheckboxChange} />
      </TableCell>
      <TableCell>
        <Label>{ticket.taskType}</Label>
      </TableCell>
      <TableCell>{ticket.title}</TableCell>
      <TableCell>
        <Icon name="plus" /> {getCallbackTime(ticket.created || undefined) || '-'}
      </TableCell>
      <TableCell>
        <Icon name="clock" /> {getCallbackTime(ticket.dueDate || undefined) || '-'}
      </TableCell>
      <TableCell>
        <div style={styles.channelIconWrapper}>
          <Icon name={channelIconsMap[ticket.channel]} />
        </div>
      </TableCell>
      <TableCell>
        {priority && (
          <div style={styles.priorityIconWrapper}>
            <Icon name={priority.icon as SemanticICONS} style={iconStyle} />
            {t(priority.text)}
          </div>
        )}
      </TableCell>
      <TableCell>
        <div>
          <WorkStatusImageContainer
            user={user}
            id={ticket.id}
            userData={userData}
            showStartWorkingOnButton={ticket.status === 'todo'}
          />
        </div>
      </TableCell>
    </TableRow>
  );
};

const connector = connect((state: State) => ({
  userData: state.userData,
  priorities: state.ticketPriorities,
  user: state.usersList.usersList.find((user) => user.UID === state.userData.UID)!
}));

export default connector(SingleLineTicketItem);
