import React, { useEffect } from 'react';
import { Icon, Image, Progress } from 'semantic-ui-react';

import type { FC } from 'react';

import WhatsappConfigurationApi from './WhatsappConfigurationApi';
import eeedoLogo from 'src/eeedo_logo_white_500x100.png';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface WhatsappCallbackPageProps {}

const WhatsappCallbackPage: FC<WhatsappCallbackPageProps> = () => {
  const params = new URLSearchParams(location.search);

  const channels = params.get('channels');
  const state = params.get('state');

  useEffect(() => {
    const controller = new AbortController();
    (async () => {
      if (channels && state) {
        let search = '';
        try {
          const channel = channels.replace(/\[|\]/g, '').split(',')[0];
          const { phone, name } = JSON.parse(state) as { phone: string; name: string; endpoint: string };

          await WhatsappConfigurationApi.upsertWhatsappConfiguration({
            name,
            phone,
            channel,
            signal: controller.signal
          });
          search = `${new URLSearchParams({ state, success: 'true' })}`;
        } catch (error) {
          search = `${new URLSearchParams({ state, error: 'true' })}`;
          console.error(error);
        }

        window.location.replace(`${location.origin}/settings/whatsapp/?${search}`);
      }
    })();

    return () => {
      controller.abort();
    };
  }, []);

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        height: '100vh',
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Icon loading name="whatsapp" color="green" size="huge" />
      <Progress active style={{ width: '200px', marginTop: '30px' }} percent={100} size="tiny" color="blue" />
      <div
        style={{
          backgroundColor: '#000',
          marginLeft: '15px',
          padding: '10px 15px',
          borderRadius: '3px'
        }}
      >
        <Image src={eeedoLogo} size="tiny" style={{ boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }} />
      </div>
    </div>
  );
};

export default React.memo(WhatsappCallbackPage);
