import { faMinus, faPlus } from '@fortawesome/pro-regular-svg-icons';
import React from 'react';

import type { Field, Tag, TicketType } from '@eeedo/types';

import Info from '../Case/Info/Info';
import SearchCollapsible from './Search/SearchCollapsible';

import type { FormattedSearch, SearchCriterion } from 'src/types/Search';

export interface SearchEntitiesAccordionProps {
  ticketTypes: TicketType[];
  defaultTicketType: number;
  criteria: SearchCriterion[];

  onSave: (
    param: keyof FormattedSearch['basic'],
    value: string | boolean | Tag[] | null | undefined,
    object: any,
    partial: boolean,
    name: string,
    text: string,
    datagroup?: string,
    entityType?: string,
    id?: any,
    entityTypes?: string[]
  ) => any;
  criteriaToFieldValues: (criteria: SearchCriterion[]) => Record<string, unknown>;
}

export const SearchEntitiesAccordion = (props: SearchEntitiesAccordionProps) => {
  const entities = props.ticketTypes
    .map((type) => {
      return type.fieldSets.map((set, index) => {
        const fieldsShown = set[set.id].filter((field: Field) => !field.params?.noSearch);
        if (fieldsShown.length === 0) {
          return <></>;
        }

        return (
          <div
            key={`search-entity-${type.name + '-' + set.displayName}-${index}`}
            style={{ padding: '14px', border: '1px solid rgba(247, 247, 247, 1)', borderRadius: '20px' }}
          >
            <SearchCollapsible title={`${type.name} - ${set.displayName}`} iconOpen={faMinus} iconClosed={faPlus}>
              <Info
                fields={set[set.id]}
                values={props.criteriaToFieldValues(props.criteria)}
                onSave={props.onSave}
                datagroup={set.group}
                params={{ ignoreFieldDisabling: true }}
                hideNoSearch
                disableWarning
              />
            </SearchCollapsible>
          </div>
        );
      });
    })
    .reduce((x, y) => x.concat(y), []);

  return <>{entities}</>;
};
