import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Popup } from 'semantic-ui-react';

import type { FC } from 'react';

import { PhoneIntegrationType } from 'src/handlers/handlePhoneCall';

interface ClickToCallPopupProps {
  onButtonClick: React.MouseEventHandler;
  type: PhoneIntegrationType;
}

const ClickToCallPopup: FC<ClickToCallPopupProps> = ({ onButtonClick, type }) => {
  const { t } = useTranslation();

  const translationKey = {
    [PhoneIntegrationType.ELISA_OC]: 'REPLY_PHONE_ELISA_OC_CALL',
    [PhoneIntegrationType.ELISA_RING]: 'REPLY_PHONE_ELISA_RING_CALL',
    [PhoneIntegrationType.MITEL]: 'REPLY_PHONE_MITEL_CALL',
    [PhoneIntegrationType.ENREACH_VOICE]: 'REPLY_PHONE_VOICE_CALL',
    [PhoneIntegrationType.GENERIC_LINK]: 'REPLY_PHONE_GENERIC_LINK'
  };

  return (
    <Popup
      content={t(translationKey[type])}
      on="hover"
      position="bottom right"
      trigger={
        <Button
          type="button"
          style={{
            padding: '0px !important',
            backgroundColor: 'transparent'
          }}
          className="clickToCallIcon"
          icon="phone"
          onClick={onButtonClick}
        />
      }
    />
  );
};

export default ClickToCallPopup;
