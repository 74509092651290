import { debounce } from 'lodash-es';
import React, { useCallback, useEffect, useState } from 'react';

import type { CSSProperties, FC } from 'react';
import type { DropdownProps, SemanticICONS, SemanticWIDTHS } from 'semantic-ui-react';

import Dropdown from 'src/Components/generic/Dropdown/Dropdown';
import { parseFieldValue } from 'src/Components/Utilities/infoField';
import { apiClient } from 'src/Utilities/httpClients';

import type { InfoFieldProps } from 'src/Components/Case/Info/InfoField/InfoFieldProps';
import type { DropdownOption } from 'src/types/Info';

interface InfoFieldDropdownProps extends Pick<InfoFieldProps, 'field' | 'values' | 'setValue' | 'onSaveData'> {
  selectionEnabled: boolean;
  renderHeader: ({ value, isNegative }: { value: any; isNegative: boolean }) => React.ReactNode;
  placeholder: string;
  noResultsMessage: string;
  fieldValue?: string | DropdownOption[];
  disabled?: boolean;
  width?: SemanticWIDTHS;
  filterOptions?: (data: any[]) => any[];
}

const InfoFieldDropdown: FC<InfoFieldDropdownProps> = ({
  field,
  values,
  fieldValue,
  placeholder,
  noResultsMessage,
  selectionEnabled,
  renderHeader,
  disabled,
  width = 10,
  setValue,
  onSaveData,
  filterOptions
}) => {
  let style: CSSProperties | undefined = {};
  let icon: SemanticICONS | undefined = undefined;

  const [loading, setLoading] = useState(false);
  const [arrayOptions, setArrayOptions] = useState<DropdownOption[]>([]);

  const fetchOptionsFromURL = useCallback(
    debounce(async (url: string) => {
      setLoading(true);

      return apiClient
        .get(url)
        .then((response) => response.data)
        .then((data) => {
          setArrayOptions(filterOptions?.(data) ?? data);
        })
        .finally(() => {
          setLoading(false);
        });
    }, 300),
    [field.params?.optionsURL]
  );

  useEffect(() => {
    if (field.options === 'FROM_VALUE') {
      setArrayOptions(fieldValue as DropdownOption[]);
    } else if (field.options === 'FETCH_FROM_URL') {
      if (field.params?.optionsURL) {
        const url = parseFieldValue({
          field: { value: field.params.optionsURL },
          values,
          shouldEncodeUriComponent: false
        });

        fetchOptionsFromURL(url);
      }
    } else if (Array.isArray(field.options)) {
      setArrayOptions(
        field.options.map((v) => ({
          text: v.name,
          value: v.value
        }))
      );

      const selectedOption = field.options.find((o) => o.value === fieldValue);
      style = selectedOption?.style;
      icon = selectedOption?.icon as SemanticICONS | undefined;
    }
  }, [field, values]);

  const onChange = useCallback<NonNullable<DropdownProps['onChange']>>(
    (_e, data) => {
      // Because of the default behavior in dropdowns, we transform them back into undefined
      const value = data.value !== null ? data.value : undefined;
      setValue?.(field, value);
      onSaveData?.(field, value);
    },
    [field, setValue, onSaveData]
  );

  const arrayValues = arrayOptions.map((option: DropdownOption) => option.value);
  const isValueMissingFromOptions =
    arrayValues.indexOf(fieldValue as string) === -1 && fieldValue !== '' && typeof fieldValue !== 'undefined';
  if (isValueMissingFromOptions) {
    arrayOptions.push({ text: fieldValue as string, value: fieldValue as string });
  }

  // TODO: not using it for demo, don't forget to apply it back
  console.log(style);
  return (
    <>
      {renderHeader({
        value: fieldValue,
        isNegative: isValueMissingFromOptions
      })}
      <Dropdown
        id={field.value}
        fluid
        search
        scrolling
        icon={icon}
        size="small"
        clearable={field.params?.clearable}
        loading={loading}
        disabled={disabled}
        selectOnBlur={false}
        options={arrayOptions}
        placeholder={placeholder}
        // selection={field.params?.selection || selectionEnabled}
        selection={true}
        value={fieldValue as string}
        noResultsMessage={noResultsMessage}
        onChange={onChange}
      />
    </>
  );
};

export default React.memo(InfoFieldDropdown);
