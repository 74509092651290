import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dimmer, Header, Loader, Modal, Table } from 'semantic-ui-react';

import type { FC } from 'react';

import styles from './Export.module.css';
import { ExportApi } from 'src/api/ExportApi';
import { convertExportRowToText, transformXlsxJsonToExportData } from 'src/Utilities/export';
import { getXLSXDataJson } from 'src/Utilities/xlsxUtils';

import type { ExportData } from 'src/types/Export';

interface ExportModalPreviewProps {
  fileId: number;
  onClose: () => void;
}

const ExportModalPreview: FC<ExportModalPreviewProps> = ({ fileId, onClose }) => {
  const { t } = useTranslation();
  const [data, setData] = useState<ExportData | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getPreviewData(fileId).then((previewData) => {
      setData(previewData);
      setIsLoading(false);
    });
  }, [fileId]);

  const getPreviewData = async (fileId: number) => {
    const { data } = await ExportApi.getFile(fileId);
    const json = await getXLSXDataJson(data);

    return transformXlsxJsonToExportData(json);
  };

  return (
    <Modal centered={false} closeIcon open size="fullscreen" onClose={() => onClose()}>
      <Header icon="external share" content={t('export.modal.title')} />

      <Modal.Content className={styles.exportModalPreviewContent}>
        {isLoading && (
          <Dimmer active inverted>
            <Loader>{t('LOADING')}</Loader>
          </Dimmer>
        )}

        {data && (
          <Table celled striped>
            <Table.Header>
              <Table.Row>
                {data.headers.map((headerName: { key: string; displayName?: string }) => (
                  <Table.HeaderCell key={headerName.key}>{headerName.displayName || headerName.key}</Table.HeaderCell>
                ))}
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {data.rows.map((row) => (
                <Table.Row key={row.id}>
                  {row.columns.map((column, columnIndex) => (
                    <Table.Cell key={column.key}>
                      {convertExportRowToText(data.headers, column, columnIndex)}
                    </Table.Cell>
                  ))}
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        )}
      </Modal.Content>
    </Modal>
  );
};

export default ExportModalPreview;
