import classNames from 'classnames';
import React from 'react';
import { Input, Table } from 'semantic-ui-react';

import type { FC } from 'react';
import type { SemanticWIDTHS } from 'semantic-ui-react';

import DateRangePickerField from '../../DateRangePickerField';
import { DATE_FORMAT, getPrettyDate } from 'src/Utilities/dates';

import type { InfoFieldProps } from './InfoFieldProps';

interface InfoDateRange extends Pick<InfoFieldProps, 'field' | 'onSaveData' | 'language'> {
  inputDisabled?: boolean;
  mongoSaveDisabled?: boolean;
  integrationSaveDisabled?: boolean;
  fieldValue?: string;
  width?: SemanticWIDTHS;
}

const parseDate = (date: string) =>
  date !== undefined ? getPrettyDate(parseInt(date, 10), { format: DATE_FORMAT }) : '';

const InfoDateRange: FC<InfoDateRange> = ({
  field,
  language,
  fieldValue,
  width = 10,
  inputDisabled,
  mongoSaveDisabled,
  integrationSaveDisabled,
  onSaveData
}) => {
  const fieldNameClass = field.name?.split(' ').join('_');
  const className = classNames({
    'Info-CompactInput': true,
    disabledInputField: mongoSaveDisabled,
    readOnlyInputField: integrationSaveDisabled
  });

  let value = fieldValue;
  if (integrationSaveDisabled || mongoSaveDisabled) {
    const dateRange = fieldValue?.split(':') || [];
    const startDate = parseDate(dateRange[0]);
    const endDate = parseDate(dateRange[1]);
    value = `${startDate} - ${endDate}`;
  }

  return (
    <Table.Cell width={width} id={'DatePickerTable_' + fieldNameClass}>
      {integrationSaveDisabled || mongoSaveDisabled ? (
        <Input
          id={'Date_' + fieldNameClass}
          disabled={inputDisabled}
          className={className}
          value={value || ''}
          size="mini"
          fluid={true}
        />
      ) : (
        <DateRangePickerField
          id={'DatePicker_' + fieldNameClass}
          onChange={(data) => {
            onSaveData?.(field, data);
          }}
          value={value}
          language={language}
          {...field.props}
        />
      )}
    </Table.Cell>
  );
};

export default React.memo(InfoDateRange);
