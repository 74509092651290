import type { CSSProperties } from 'react';

export const headerWrapper: CSSProperties = { display: 'flex' };

export const header: CSSProperties = {
  margin: '0px',
  marginTop: 'auto',
  marginBottom: 'auto',
  // for demo
  fontWeight: 600,
  fontSize: '13px',
  lineHeight: '20px'
};

export const buttonWrapper: CSSProperties = { display: 'flex' };

export const button: CSSProperties = {
  padding: '0px !important',
  backgroundColor: 'transparent',
  color: 'green'
};
