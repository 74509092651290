import { faRefresh } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import type { FC } from 'react';

import css from './MainTab.module.css';
import * as styles from './MainTab.style';
import { fetchTickets } from 'src/actions/ticketsActions';
import { setTicketListActiveTab, setToolsActiveTab } from 'src/reducers/ticketListActiveTabsReducer';
import { StaticTabs } from 'src/types/TicketList';

const MainTab: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [inactive, setInactive] = useState(false);

  const onTabClick = useCallback(() => {
    setInactive(true);
    setTimeout(() => {
      setInactive(false);
    }, 5000);

    dispatch(setToolsActiveTab());
    dispatch(setTicketListActiveTab(StaticTabs.MAIN_VIEW));
  }, []);

  const onIconClick = useCallback(() => {
    if (inactive) {
      return;
    }

    dispatch(fetchTickets());
  }, []);

  const className = classNames(css.active, { [css.inactive]: inactive });

  return (
    <div style={styles.wrapper} onClick={onTabClick}>
      <div>{t('MAIN_VIEW')}</div>
      <FontAwesomeIcon icon={faRefresh} className={className} style={styles.icon} onClick={onIconClick} />
    </div>
  );
};

export default React.memo(MainTab);
