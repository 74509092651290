import iziToast from 'izitoast';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Redirect } from 'react-router-dom';
import { Form, Icon } from 'semantic-ui-react';

import type { FC } from 'react';

import Button from './Components/generic/Button/Button';
import Input from './Components/generic/Input/Input';

interface ForgotPasswordProps {
  handleForgottenPassword: (email: string) => Promise<{ response?: { data: { msg: string } } }>;
  isUserAuthenticated: boolean;
  onPasswordResetSuccess?: () => void;
}

const ForgotPassword: FC<ForgotPasswordProps> = ({ isUserAuthenticated, handleForgottenPassword }) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleSubmit = () => {
    setIsLoading(true);

    handleForgottenPassword(email).then(({ response }) => {
      if (response && response.data.msg === 'NOT_ALLOWED_FEATURE') {
        iziToast.error({
          message: `${t('NOT_ALLOWED_FEATURE')}`,
          position: 'bottomCenter'
        });
      } else {
        iziToast.info({
          message: `${t('LOGIN_FORGOTPASSWORD_REQUEST_SUBMITTED')}`,
          position: 'bottomCenter'
        });
      }

      setIsLoading(false);
    });
  };

  return (
    <div className="loginWrapper">
      {isUserAuthenticated ? (
        <Redirect to="/" />
      ) : (
        <div className="loginForm">
          <Form>
            <div className="loginHeader">
              <h1>{t('LOGIN_FORGOTPASSWORD_TITLE')}</h1>
              <h2 style={{ textAlign: 'center' }}>{t('LOGIN_FORGOTPASSWORD_INSTRUCTIONS')}</h2>
            </div>
            <div className="marginTop25px loginFormManualLogin">
              <Input
                label="Email"
                name="email"
                placeholder={t('PLACEHOLDER_EMAIL')}
                onChange={handleChange}
                value={email}
              />
              <Button
                type="primary"
                iconLeft={<Icon name="sign-out" />}
                content={t('LOGIN_FORGOTPASSWORD_SEND')}
                onClick={handleSubmit}
                disabled={isLoading}
                center
              />
              <div style={{ textAlign: 'right' }}>
                <Link to="/login"> {t('LOGIN_FORGOTPASSWORD_RETURN')} </Link>
              </div>
            </div>
          </Form>
        </div>
      )}
    </div>
  );
};

export default ForgotPassword;
