import { generatePath } from 'react-router-dom';

import type { ExportTemplate } from 'src/types/Export';
import { apiClient } from 'src/Utilities/httpClients';

export const ExportApi = {
  addExportJob: (ids: number[], template: number) => {
    return apiClient.post('/export/job', { ids, template });
  },
  getExportJobs: () => {
    return apiClient.get('/export/jobs');
  },
  getExportDataTemplates: () => {
    return apiClient.get<ExportTemplate[]>('/export/templates');
  },
  getFile: (fileId: number) => {
    return apiClient.get(generatePath('/file/files/:fileId', { fileId }), { responseType: 'blob', timeout: 0 });
  }
};
