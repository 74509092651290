import { faClone } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import iziToast from 'izitoast';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'semantic-ui-react';

import type { FC } from 'react';

import Button from '../../generic/Button/Button';
import { contentIdToHistoryPrefix } from 'src/Utilities/history';
import { getTicketHash } from 'src/Utilities/ticket';

import type { ContentTypes } from 'src/types/ContentTypes';

interface Props {
  title: string;
  caseId: string;
  taskCreated: number;
  contentType: ContentTypes;
}

const TopBarCopyDropdown: FC<Props> = ({ title, caseId, taskCreated }) => {
  const { t } = useTranslation();

  const onIDCopy = () => {
    navigator.clipboard.writeText(caseId);
    iziToast.info({
      message: t('COPIED')
    });
  };

  const onLinkCopy = () => {
    navigator.clipboard.writeText(`${window.location.origin}/${contentIdToHistoryPrefix(caseId)}/${caseId}`);
    iziToast.info({
      message: t('TICKET_LINK_COPIED')
    });
  };

  const onTextCopy = () => {
    navigator.clipboard.writeText(title);
    iziToast.info({
      message: t('COPIED')
    });
  };

  const onLinkWithTitleCopy = () => {
    navigator.clipboard.writeText(
      `[${title}](${window.location.origin}/${contentIdToHistoryPrefix(caseId)}/${caseId})`
    );
    iziToast.info({
      message: t('COPIED')
    });
  };

  const onEmailIdentifierClick = () => {
    navigator.clipboard.writeText(`[${caseId}${getTicketHash(caseId, taskCreated)}]`);
    iziToast.info({
      message: t('COPIED')
    });
  };

  return (
    <Dropdown
      trigger={<Button icon={<FontAwesomeIcon icon={faClone} />} />}
      pointing="top right"
      className="icon topBarCopyDropdown"
      icon={false}
    >
      <Dropdown.Menu>
        <Dropdown.Item onClick={onIDCopy}>{t('top_bar.copy_dropdown.copy_id')}</Dropdown.Item>
        <Dropdown.Item onClick={onLinkCopy}>{t('top_bar.copy_dropdown.copy_link')}</Dropdown.Item>
        <Dropdown.Item onClick={onLinkWithTitleCopy}>{t('top_bar.copy_dropdown.link_with_title')}</Dropdown.Item>
        <Dropdown.Item onClick={onTextCopy}>{t('top_bar.copy_dropdown.copy_title')}</Dropdown.Item>
        <Dropdown.Item onClick={onEmailIdentifierClick}>{t('top_bar.copy_dropdown.email_identifier')}</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default TopBarCopyDropdown;
