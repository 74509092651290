import { faChevronRight, faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import type { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import type { FC } from 'react';

import Collapsible from 'src/Components/generic/Collapsible/Collapsible';
import Label from 'src/Components/generic/Label/Label';

interface SearchCollapsibleProps {
  title: string;
  children: React.ReactNode;
  openByDefault?: boolean;
  iconOpen?: IconDefinition;
  iconClosed?: IconDefinition;

  amount?: number;
}

const SearchCollapsible: FC<SearchCollapsibleProps> = ({
  title,
  amount,
  openByDefault,
  iconOpen,
  iconClosed,
  children
}) => (
  <Collapsible
    style={{ width: '100%' }}
    openByDefault={openByDefault}
    heading={(isOpen) => (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '12px 0px',
          width: '100%'
        }}
      >
        <div style={{ fontSize: '15px', fontWeight: 600, lineHeight: '24px', color: 'rgba(17, 17, 17, 1)' }}>
          {title} {amount !== undefined && <Label>{amount}</Label>}
        </div>
        <FontAwesomeIcon icon={isOpen ? iconOpen ?? faChevronUp : iconClosed ?? faChevronRight} />
      </div>
    )}
  >
    <div style={{ display: 'flex', flexDirection: 'column', gap: '12px', paddingTop: '12px' }}>{children}</div>
  </Collapsible>
);

export default React.memo(SearchCollapsible);
