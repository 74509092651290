import classNames from 'classnames';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Menu } from 'semantic-ui-react';

import type { Channel, PersonalData, Priority, Tag, TicketType, UserWithProfile } from '@eeedo/types';
import type { FC } from 'react';

import TicketListDateRow from './TicketListDateRow';
import * as styles from './TicketListItem.style';
import TicketListRowIcon from './TicketListRowIcon';
import TicketListTitleRow from './TicketListTitleRow';
import TicketMeta from './TicketMeta';
import { activateTab } from 'src/actions/tabActionsRTK';
import { activateTicket } from 'src/actions/ticketsActions';
import FeatureFlags from 'src/api/FeatureFlags';
import ChannelType from 'src/Components/CommentIconContent/ChannelType';
import { typeToPrefix } from 'src/types/ContentTypes';
import { filterNonDefaultTags } from 'src/types/Tag';
import { getPhoneIcon } from 'src/Utilities/getPhoneIcon';
import { coloredTagsFirst } from 'src/Utilities/ticketList';

import type { ContentTypes } from 'src/types/ContentTypes';
import type { WorkStatus, WorkStatusItem } from 'src/types/RealTimeService';
import type { TicketListTicket } from 'src/types/Ticket';
import type { DueDateThreshold } from 'src/types/TicketType';

import './TicketListItem.css';

export interface TicketItemProps {
  // ticket specific props
  id: TicketListTicket['id'];
  title: TicketListTicket['title'];
  status: TicketListTicket['status'];
  created: TicketListTicket['created'];
  touched: TicketListTicket['touched'];
  dueDate: TicketListTicket['dueDate'];
  taskType: TicketListTicket['taskType'];
  originalContact: TicketListTicket['originalContact'];
  originalDirection: TicketListTicket['originalDirection'];
  lastContactAddress: TicketListTicket['lastContactAddress'];
  delegates: TicketListTicket['delegates'] | TicketListTicket['delegatedTo'];
  // rest of the component props
  type?: ContentTypes;
  index: number;
  selected: boolean;
  channel: Channel | undefined;
  priority: Priority | undefined;
  tags: Tag[];
  active: boolean | null | undefined;
  ticketTypes: TicketType[];
  usersList: UserWithProfile[];
  to?: number;
  from?: number;
  workStatus?: WorkStatus;
  currentUserData: PersonalData;
  shouldOpenTicket?: boolean;

  removeTicketLinks?: (id?: number, targetId?: number) => void;
  startWorkingOn?: (currentUserId: string, taskId: string) => void;
}

const TicketListItem: FC<TicketItemProps> = ({
  dueDate,
  id,
  title,
  created,
  taskType,
  status,
  touched,
  originalContact,
  originalDirection,
  lastContactAddress,
  type,
  index,
  selected,
  channel,
  priority,
  tags: originalTags,
  active,
  ticketTypes,
  delegates,
  usersList,
  to,
  from,
  removeTicketLinks,
  startWorkingOn,
  workStatus,
  currentUserData,
  shouldOpenTicket = true
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onClick = () => {
    if (shouldOpenTicket) {
      dispatch(activateTicket(id));
      dispatch(activateTab(id));
    }
  };

  const canStartWork =
    typeof startWorkingOn === 'function' &&
    FeatureFlags.isFlagOn('ENABLE_START_WORKING_ON_FROM_TICKETLIST_BY_WHOLE_ELEMENT');
  const startWorkingTicket = () => (canStartWork && !currentUser ? startWorkingOn(currentUserData.UID, id) : false);

  const removeTicketLink = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();

    removeTicketLinks?.(to, from);
  };

  const tags = filterNonDefaultTags(originalTags ?? []);
  const timeRemaining = Number(dueDate) - moment().unix();
  const findDueDateThreshild = (value: DueDateThreshold) => {
    if (value.min && value.max) {
      return timeRemaining > value.min && timeRemaining < value.max;
    } else if (value.min) {
      return timeRemaining > value.min;
    } else if (value.max) {
      return timeRemaining < value.max;
    }

    return false;
  };

  const correctTicketType = ticketTypes.find((tt: TicketType) => tt.name === taskType);
  const thresholdColor =
    correctTicketType?.dueDateThresholds?.find?.(findDueDateThreshild)?.color ?? 'rgba(255,255,255,0.0)';

  const sortedTags = coloredTagsFirst(tags);

  const currentStatus = workStatus?.status.find(
    (status: WorkStatusItem) => typeToPrefix(type) + status.ticketId === id
  );

  const currentUser = currentStatus && usersList.find((user) => user.UID === 'USR' + currentStatus.UID);

  const mainClassName = classNames({ selected: selected === true });
  const className = classNames({ 'ticket-link ticket-link-active': !!active, 'ticket-link': !active });

  return (
    <Menu.Item
      key={index}
      name={title}
      className={mainClassName}
      onClick={startWorkingTicket}
      style={styles.item}
      data-test-id={id}
    >
      <div onClick={onClick} className={className}>
        <TicketListTitleRow
          id={id}
          type={type}
          title={title}
          channel={channel}
          created={created}
          touched={touched}
          dueDate={dueDate}
          priority={priority}
          taskType={taskType}
          delegates={delegates}
          ticketTypes={ticketTypes}
          originalDirection={originalDirection}
        />
        <TicketListDateRow created={created} touched={touched} dueDate={dueDate} />
        {FeatureFlags.isFlagOn('ENABLE_TICKETLIST_ORIGINAL_CONTACT') && originalContact && (
          <TicketListRowIcon content={originalContact} name="mail" />
        )}
        {FeatureFlags.isFlagOn('ENABLE_TICKETLIST_LAST_CONTACT_ADDRESS') && lastContactAddress && (
          <TicketListRowIcon content={lastContactAddress} name="mail" />
        )}
        {FeatureFlags.isFlagOn('ENABLE_TICKETLIST_ORIGINAL_DIRECTION') && channel?.id === ChannelType.Phone && (
          <TicketListRowIcon
            content={t(`TICKETLIST_ORIGINAL_DIRECTION_${originalDirection.toUpperCase()}`)}
            name={getPhoneIcon(originalDirection)}
          />
        )}
        <TicketMeta
          id={id}
          to={to}
          from={from}
          type={type}
          taskType={taskType}
          sortedTags={sortedTags}
          tagsLength={tags.length}
          currentUserData={currentUserData}
          removeTicketLink={removeTicketLink}
        />
      </div>
      {status && <div className={status + ' ticket-status'} style={styles.status(thresholdColor)} />}
    </Menu.Item>
  );
};

export default React.memo(TicketListItem);
