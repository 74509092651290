import classNames from 'classnames';
import React from 'react';
import { Dropdown as SDropdown } from 'semantic-ui-react';

import type { ComponentProps, FC } from 'react';

import './Dropdown.css';

type DropdownColor = 'dark_violet';

export interface DropdownProps extends ComponentProps<typeof SDropdown> {
  color?: DropdownColor;
  iconPosition?: 'left' | 'right';
}

const Dropdown: FC<DropdownProps> = ({ color, iconPosition, ...props }) => {
  const dropdownClass = classNames(
    {
      [`color_${color}`]: !!color,
      ['icon_position_left']: iconPosition === 'left'
    },
    props.className
  );

  return <SDropdown {...props} className={dropdownClass} />;
};

export default React.memo(Dropdown);
