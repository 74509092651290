// https://docs.sheetjs.com/docs/getting-started/installation/frameworks/#dynamic-imports

export const checkXLSXFileRequiredHeaders = async (
  file: File,
  requiredHeaders: string[]
): Promise<{ name: string; valid: boolean }[]> => {
  const XLSX = await import('./xlsxRemap');
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function (event) {
      const data = event.target?.result;
      const readedData = XLSX.read(data, { type: 'binary' });
      const dataParse = XLSX.utils.sheet_to_json<any>(readedData.Sheets[readedData.SheetNames[0]], { header: 1 });

      if (dataParse[0]) {
        resolve(
          requiredHeaders.map((header: string) => {
            return { name: header, valid: !!dataParse[0].includes(header) };
          })
        );
      }
      resolve([]);
    };

    reader.onerror = reject;
    reader.onabort = reject;

    reader.readAsBinaryString(file);
  });
};

export const getXLSXFileRecordsAmount = async (file: File): Promise<number> => {
  const XLSX = await import('./xlsxRemap');

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function (event) {
      const data = event.target?.result;
      const readedData = XLSX.read(data, { type: 'binary' });
      const dataParse = XLSX.utils.sheet_to_json<any>(readedData.Sheets[readedData.SheetNames[0]], { header: 1 });

      resolve(dataParse.length - 1);
    };

    reader.onerror = reject;
    reader.onabort = reject;

    reader.readAsBinaryString(file);
  });
};

export const getXLSXFirstRecordData = async (file: File): Promise<{ [key: string]: string }> => {
  const XLSX = await import('./xlsxRemap');
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function (event) {
      const data = event.target?.result;
      const readedData = XLSX.read(data, { type: 'binary' });
      const dataParse = XLSX.utils.sheet_to_json<any>(readedData.Sheets[readedData.SheetNames[0]], { header: 1 });

      const firstRecordData = {};
      dataParse[0].map((header: string, index: number) => (firstRecordData[header] = dataParse[1][index]));
      resolve(firstRecordData);
    };

    reader.onerror = reject;
    reader.onabort = reject;

    reader.readAsBinaryString(file);
  });
};

export const getXLSXDataJson = async (blob: Blob): Promise<string[][]> => {
  const XLSX = await import('./xlsxRemap');

  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      if (reader.readyState === FileReader.DONE) {
        const data = reader.result;
        const readData = XLSX.read(data, { type: 'binary' });
        const json = XLSX.utils.sheet_to_json<string[]>(readData.Sheets[readData.SheetNames[0]], { header: 1 });
        resolve(json);
      } else {
        reject(new Error('Error reading Blob'));
      }
    };

    reader.onerror = reject;
    reader.onabort = reject;

    reader.readAsArrayBuffer(blob);
  });
};
