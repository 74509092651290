import type { CSSProperties } from 'react';

export const wrapper: CSSProperties = {
  padding: '0 20px',
  marginBottom: '10px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
};

export const menu: CSSProperties = { display: 'flex', overflowY: 'auto', maxWidth: '150px' };
