import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import iziToast from 'izitoast';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'semantic-ui-react';

import type { TicketType } from '@eeedo/types';
import type { FC } from 'react';

import { addEntityToCase, createContent } from 'src/actions/ticketsActions';
import { defaultTask } from 'src/api/Task';
import Button from 'src/Components/generic/Button/Button';
import TicketTypeDropdown from 'src/Components/generic/TicketTypeDropdown';

import type { State } from 'src/types/initialState';
import type { Entity, Ticket } from 'src/types/Ticket';

interface EntityTicketCreationModalProps {
  entity: Entity;
  onTicketCreateSuccess(): void;
}

const EntityTicketCreationModal: FC<EntityTicketCreationModalProps> = ({ entity, onTicketCreateSuccess }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const ticketTypes = useSelector<State, TicketType[]>((state) => state.ticketTypes);
  const [selectedTicketType, setValue] = useState<TicketType>();

  const onCreateTicketClick = useCallback(async () => {
    try {
      const ticket = await (
        dispatch(
          createContent({ ...defaultTask(selectedTicketType?.name), direction: null }, true)
        ) as unknown as Promise<Ticket>
      ).catch((error) => {
        console.error(error);
        throw Error(t('entityViewer.create_entity_ticket_failed'));
      });

      await (
        dispatch(
          addEntityToCase(ticket.id, { taskType: ticket.taskType, _id: entity._id, _type: entity._type })
        ) as unknown as Promise<void>
      ).catch((error) => {
        console.error(error);
        throw Error(t('entityViewer.create_entity_add_to_ticket_failed'));
      });

      iziToast.success(t('entityViewer.create_entity_ticket_success'));
      onTicketCreateSuccess();
    } catch (error) {
      iziToast.error({ message: (error as Error).message, timeout: 3000 });
    }
  }, [selectedTicketType]);

  const handleTypeChange = useCallback(
    (val: string) => {
      const ticketType = ticketTypes.find((type) => type.name === val);
      setValue(ticketType);
    },
    [ticketTypes]
  );

  return (
    <Modal
      trigger={<Button icon={<FontAwesomeIcon icon={faPlus} />} type="primary" data-test="addButton" />}
      size="tiny"
    >
      <Modal.Header>{t('entityViewer.create_entity_ticket')}</Modal.Header>
      <Modal.Content>
        <label>{t('entityViewer.create_entity_ticket_type')}</label>
        <TicketTypeDropdown
          value={selectedTicketType?.name || ''}
          handleTypeChange={handleTypeChange}
          entityType={entity._type}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button type="primary" disabled={!selectedTicketType} onClick={onCreateTicketClick}>
          {t('entityViewer.create_entity')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default React.memo(EntityTicketCreationModal);
