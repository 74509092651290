import React from 'react';
import { Accordion, AccordionTitle, Button, ButtonGroup, Dropdown } from 'semantic-ui-react';

import type { FC } from 'react';

import SingleLineAccordionTab from './SingleLineAccordionTab';

import type { TicketListTicket } from 'src/types/Ticket';

import './SingleLineTicketView.css';

import type { DropdownOption } from 'src/types/Info';

export type AccordionTab = {
  index: number;
  status: 'draft' | 'inReview' | 'waitingToBePublished' | 'published' | 'waste' | 'todo' | 'doing' | 'done';
  tickets: TicketListTicket[];
};

interface SingleLineTicketViewContentProps {
  accordionTabs: AccordionTab[];
  dropdownOptions: DropdownOption[];
}

const SingleLineTicketViewContent: FC<SingleLineTicketViewContentProps> = ({ accordionTabs, dropdownOptions }) => {
  return (
    <div className="toastSearchTarget">
      <Accordion fluid styled style={{ borderRadius: 0, flex: 1, height: '100%' }}>
        {accordionTabs.map((tab) => (
          <SingleLineAccordionTab index={tab.index} status={tab.status} tickets={tab.tickets} />
        ))}

        <AccordionTitle>
          <ButtonGroup color="blue">
            <Button basic color="blue">
              Perform on selected
            </Button>
            <Dropdown className="button icon" floating options={dropdownOptions} trigger={<></>} />
          </ButtonGroup>
        </AccordionTitle>
      </Accordion>
    </div>
  );
};

export default SingleLineTicketViewContent;
