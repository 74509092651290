import { connect } from 'react-redux';

import { setEnreachVoiceLoggedIn, setEnreachVoiceStatus } from 'src/actions/integrationsActionsRTK';
import EnreachVoice from 'src/Components/PhoneServices/EnreachVoice';
import { handleIncomingPhoneCall } from 'src/handlers/handlePhoneCall';

import type { IntegrationData } from 'src/handlers/handlePhoneCall';
import type { State } from 'src/types/initialState';
import type { EnreachStatus } from 'src/Utilities/intergrations/callEnreachVoice';

const mapStateToProps = (state: State, ownProps: any) => {
  return {
    callRequests: state.callRequests,
    phoneConfigurations: state.phoneConfigurations,
    ticketTypes: state.ticketTypes,
    userData: state.userData,
    ...ownProps
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    handleIncomingPhoneCall: (callObject: IntegrationData) => {
      return dispatch(handleIncomingPhoneCall(callObject));
    },
    setEnreachVoiceLoggedIn: (loggedIn: boolean) => {
      dispatch(setEnreachVoiceLoggedIn({ loggedIn }));
    },
    setEnreachVoiceStatus: (status: EnreachStatus) => {
      dispatch(setEnreachVoiceStatus({ status }));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EnreachVoice);
