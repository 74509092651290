import React from 'react';

import type { FC } from 'react';

import DesktopLayout from './Desktop/DesktopLayout';
import MobileLayout from './Mobile/MobileLayout';

interface Props {
  mobileMode: boolean;
  children?: React.ReactNode;
}

const Layout: FC<Props> = ({ mobileMode, children }) => {
  if (mobileMode) {
    return <MobileLayout children={children} />;
  } else {
    return <DesktopLayout />;
  }
};

export default Layout;
