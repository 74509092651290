import React from 'react';
import { connect } from 'react-redux';
import { AutoSizer, List } from 'react-virtualized';

import type { FC } from 'react';
import type { ConnectedProps } from 'react-redux';
import type { ListRowRenderer } from 'react-virtualized';

import TicketListItem from './TicketListItem/TicketListItem';
import FeatureFlags from 'src/api/FeatureFlags';
import { getTags } from 'src/Utilities/tags';
import { getChannelName, getPriorityName } from 'src/Utilities/ticketList';

import type { TFeatureFlag } from 'src/api/FeatureFlags';
import type { ContentTypes } from 'src/types/ContentTypes';
import type { State } from 'src/types/initialState';
import type { TicketListTicket } from 'src/types/Ticket';

const heightFlags: TFeatureFlag[] = [
  'ENABLE_TICKETLIST_ORIGINAL_CONTACT',
  'ENABLE_TICKETLIST_LAST_CONTACT_ADDRESS',
  'ENABLE_TICKETLIST_ORIGINAL_DIRECTION'
];

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface TicketListProps extends ConnectedProps<typeof connector> {
  active: boolean;
  type: ContentTypes;
  tickets: TicketListTicket[];
  shouldOpenTicket?: boolean;
  selectedTicketId?: string | null;

  startWorkingOn?: (currentUserId: string, taskId: string) => void;
}

const TicketList: FC<TicketListProps> = ({
  type,
  active,
  shouldOpenTicket,
  selectedTicketId,
  tags,
  tickets,
  channels,
  usersList,
  workStatus,
  priorities,
  ticketTypes,
  currentUserData,
  startWorkingOn
}) => {
  const rowRenderer: ListRowRenderer = ({
    key,
    index, // Index of row within collection
    style, // Style object to be applied to row (to position it)
    parent // Props of parent, eg. tickets, channels
  }) => {
    const ticket = tickets[index];
    const tags = ticket.tags ? getTags(ticket.tags, parent.props.tags) : [];

    return (
      <div key={key} style={{ ...style }} className="virtualized-ticketListItem">
        <TicketListItem
          key={index}
          type={type}
          index={index}
          selected={selectedTicketId === ticket.id}
          ticketTypes={parent.props.ticketTypes}
          dueDate={ticket.dueDate}
          active={parent.props.activeTicket === ticket.id}
          tags={tags}
          id={ticket.id}
          channel={getChannelName(ticket.channel, parent.props.channels)}
          priority={getPriorityName(ticket.priority, priorities)}
          title={ticket.title}
          created={ticket.created}
          taskType={ticket.taskType}
          status={ticket.status}
          delegates={ticket.delegatedTo || ticket.delegates}
          usersList={parent.props.usersList}
          touched={ticket.touched}
          startWorkingOn={startWorkingOn}
          workStatus={workStatus}
          currentUserData={currentUserData}
          originalContact={ticket.originalContact}
          originalDirection={ticket.originalDirection}
          lastContactAddress={ticket.lastContactAddress}
          shouldOpenTicket={shouldOpenTicket}
        />
      </div>
    );
  };

  const defaultHeight = 85 + 14 * heightFlags.filter((flag) => FeatureFlags.isFlagOn(flag)).length;

  return (
    <AutoSizer>
      {({ height, width }) => (
        <List
          width={width}
          height={height}
          isActive={active}
          overscanRowCount={10}
          rowCount={tickets.length}
          rowHeight={defaultHeight}
          estimatedRowSize={defaultHeight}
          // our props
          tags={tags}
          tickets={tickets}
          channels={channels}
          ticketTypes={ticketTypes}
          rowRenderer={rowRenderer}
          usersList={usersList}
        />
      )}
    </AutoSizer>
  );
};

const connector = connect((state: State) => ({
  tags: state.tags,
  channels: state.channels,
  ticketTypes: state.ticketTypes,
  priorities: state.ticketPriorities,
  usersList: state.usersList.usersList,
  workStatus: state.workStatus,
  currentUserData: state.userData
}));

export default connector(TicketList);
