import { DefaultTicketsOrdering } from '@eeedo/types';
import { faSlidersSimple } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Menu, MenuItem } from 'semantic-ui-react';

import type { FC } from 'react';

import { setToolsActiveTab } from 'src/reducers/ticketListActiveTabsReducer';
import { useAppSelector } from 'src/store';

const [defaultDirection, defaultSorting] = DefaultTicketsOrdering;

const Tabs: FC = () => {
  const dispatch = useDispatch();
  const active = useAppSelector((state) => state.ticketListActiveTabs.tools);
  const { filters, sorting, direction } =
    useAppSelector((state) => state.ticketListTabs[state.ticketListActiveTabs.main]) ?? {};

  const onFilterClick = useCallback(() => {
    dispatch(setToolsActiveTab('filter'));
  }, [dispatch]);
  const onSearchClick = useCallback(() => {
    dispatch(setToolsActiveTab('search'));
  }, [dispatch]);

  const isFilterOn =
    !Object.keys(filters ?? {}).every((k) => !filters?.[k]) ||
    (sorting && sorting !== defaultSorting) ||
    (direction && direction !== defaultDirection);

  return (
    <Menu pointing secondary>
      <MenuItem
        name="filter"
        active={active === 'filter'}
        onClick={onFilterClick}
        icon={
          <FontAwesomeIcon
            style={{ marginRight: '5px' }}
            color={isFilterOn ? 'red' : undefined}
            icon={faSlidersSimple}
          />
        }
      />
      <MenuItem name="search" icon="search" active={active === 'search'} onClick={onSearchClick} />
    </Menu>
  );
};

export default React.memo(Tabs);
