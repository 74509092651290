import { faImages } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Popup } from 'semantic-ui-react';

import type { FC } from 'react';

import Button from '../generic/Button/Button';
import FeatureFlags from 'src/api/FeatureFlags';
import { setShowImages } from 'src/reducers/commentsMenuReducer';

import type { State } from 'src/types/initialState';

const ImagesToggle: FC = () => {
  const { t } = useTranslation();
  const contentId = useSelector((state: State) => state.activeTicketTab);
  const showImages = useSelector((state: State) =>
    state.commentsMenu.showImages === undefined
      ? FeatureFlags.isFlagOn('ENABLE_COMMENT_IMAGES')
      : state.commentsMenu.showImages
  );
  const dispatch = useDispatch();
  if (!contentId) {
    return null;
  }
  const onClick = () => {
    dispatch(setShowImages(!showImages));
  };

  return (
    <Popup
      trigger={
        <Button icon={<FontAwesomeIcon icon={faImages} />} onClick={onClick} type={showImages ? 'primary' : 'normal'} />
      }
    >
      <Popup.Content>{showImages ? t('comments.popups.hide_images') : t('comments.popups.show_images')}</Popup.Content>
    </Popup>
  );
};

export default React.memo(ImagesToggle);
