import React, { useCallback, useEffect, useState } from 'react';
import { getI18n, Translation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Dropdown, Icon, List, Popup } from 'semantic-ui-react';

import type { DropdownProps, SemanticICONS } from 'semantic-ui-react';

import EnvSettings from './api/EnvSettings';
import UserAvatar from './Components/User/UserAvatar';
import { setHtmlLangAttribute } from './Utilities/helper';

import './ProfileDropdown.css';

import { useDispatch, useSelector } from 'react-redux';

import { logout } from './actions/authActions';
import Internationalization from './i18n';

import type { State } from './types/initialState';

const ProfileDropdown = () => {
  const userData = useSelector((state: State) => state.userData);
  const dispatch = useDispatch();
  const manualLogout = useCallback(() => {
    dispatch(logout({ type: 'manual' }));
  }, [dispatch]);

  let defaultLang = 'fi';
  if (userData && userData.userPreferences) {
    defaultLang = userData.userPreferences.defaultLanguage;
  }

  const [lang, setLang] = useState(defaultLang);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    getI18n().changeLanguage(lang);
    setHtmlLangAttribute(lang);
  }, []);

  const changeLanguage = (i18nInstance: any, data: DropdownProps) => {
    if (Array.isArray(data.value) || !data.value || typeof data.value !== 'string') {
      return;
    }

    Internationalization.changeLanguage(i18nInstance, data.value);
    setLang(lang);
  };

  const getProfileTrigger = (isOpen: boolean) => {
    const icon: SemanticICONS = isOpen ? 'chevron up' : 'chevron down';
    return (
      <div
        style={{
          alignItems: 'center',
          backgroundColor: 'white',
          borderRadius: '20px',
          cursor: 'pointer',
          display: 'flex',
          float: 'right',
          padding: '5px'
        }}
      >
        <UserAvatar
          UID={userData.UID}
          className="navBarProfileAvatar"
          round
          size="35"
          imgClassName="ProfileDropdown-Image"
        />
        <span style={{ marginLeft: '5px' }}>
          {`${userData.profile.firstName} ${userData.profile.lastName}`}
          <Icon name={icon} style={{ marginLeft: '5px' }} />
        </span>
      </div>
    );
  };

  return (
    <Translation ns="translations">
      {(t, { i18n }) => (
        <Popup
          open={isOpen}
          onOpen={() => setIsOpen(true)}
          onClose={() => setIsOpen(false)}
          style={{ minWidth: '200px' }}
          on="click"
          position="bottom right"
          trigger={getProfileTrigger(isOpen)}
        >
          <Popup.Content>
            <List size="medium">
              <List.Item as="a">
                <Dropdown
                  selectOnBlur={false}
                  value={lang}
                  onChange={(e, data) => changeLanguage(i18n, data)}
                  options={Internationalization.getLanguages()}
                />
              </List.Item>

              <List.Item href={EnvSettings.getSettings().GUIDE_URL} target={'_blank'}>
                <Icon name="question" /> {t('INSTRUCTIONS')}
              </List.Item>

              <List.Item>
                <Icon name="hashtag" /> {t('VERSION')} {import.meta.env.VITE_CONTAINER_IMAGE}
              </List.Item>

              <List.Item>
                <Icon name="settings" className="profileDropdownSettings" />
                <Link to="/settings" className="profileDropdownSettings">
                  {t('PREFERENCES_EDIT_SETTINGS')}
                </Link>
              </List.Item>

              <List.Item className="logOutLink" as="a" onClick={manualLogout}>
                <Icon name="log out" /> {t('MAIN_TOPBAR_LOGOUT')}
              </List.Item>
            </List>
          </Popup.Content>
        </Popup>
      )}
    </Translation>
  );
};

export default ProfileDropdown;
