import { faPlus, faSave } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Popup } from 'semantic-ui-react';

import type { Field } from '@eeedo/types';
import type { FC } from 'react';

import * as styles from './EntityNewField.style';
import Button from 'src/Components/generic/Button/Button';
import Input from 'src/Components/generic/Input/Input';

import type { OnInputChange } from 'src/types/Campaign';

interface EntityNewFieldProps {
  disabled: boolean;

  onChange(field: Field): void;
}

const EntityNewField: FC<EntityNewFieldProps> = ({ disabled, onChange }) => {
  const { t } = useTranslation();
  const [newField, setNewField] = useState<Field | undefined>();

  const onAddClick = useCallback(() => {
    setNewField({ name: '', value: '' });
  }, []);
  const onNameChange = useCallback<OnInputChange>(
    (_e, { value }) => {
      setNewField({ ...newField, name: value } as Field);
    },
    [newField]
  );
  const onValueChange = useCallback<OnInputChange>(
    (_e, { value }) => {
      setNewField({ ...newField, value });
    },
    [newField]
  );
  const onSave = useCallback(() => {
    onChange(newField as Field);
    setNewField(undefined);
  }, [newField]);

  return (
    <>
      {newField && (
        <Form>
          <Form.Group>
            <Form.Field width={7}>
              <label>{t('entityViewer.create_entity_new_field_label')}</label>
              <Input value={newField?.name} onChange={onNameChange} />
            </Form.Field>
            <Form.Field width={7}>
              <label>{t('entityViewer.create_entity_new_field_key')}</label>
              <Input value={newField?.value} onChange={onValueChange} />
            </Form.Field>
            <Form.Field width={2} style={styles.saveFieldWrapper}>
              <Popup
                trigger={<Button icon={<FontAwesomeIcon icon={faSave} />} type="primary" onClick={onSave} />}
                content={t('entityViewer.create_entity_save_field')}
              />
            </Form.Field>
          </Form.Group>
        </Form>
      )}
      {!newField && (
        <div style={styles.addFieldWrapper}>
          <Popup
            trigger={
              <Button
                icon={<FontAwesomeIcon icon={faPlus} />}
                type="positive"
                disabled={disabled}
                onClick={onAddClick}
              />
            }
            content={t('entityViewer.create_entity_add_field')}
          />
        </div>
      )}
    </>
  );
};

export default React.memo(EntityNewField);
