import { connect } from 'react-redux';

import ElisaOC from '../Components/PhoneServices/ElisaOC';
import { handleIncomingPhoneCall } from '../handlers/handlePhoneCall';
import { removeCallRequest } from 'src/actions/phoneActions';

import type { IntegrationData } from '../handlers/handlePhoneCall';
import type { State } from 'src/types/initialState';

const mapStateToProps = (state: State, ownProps: any) => {
  return {
    ticketTypes: state.ticketTypes,
    userData: state.userData,
    phoneConfigurations: state.phoneConfigurations,
    callRequests: state.callRequests,
    ...ownProps
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    removeCallRequest: (ticketId: number, phoneNumber: string) => {
      dispatch(
        removeCallRequest({
          ticketId,
          phoneNumber
        })
      );
    },
    handleIncomingPhoneCall: (callObject: IntegrationData) => {
      return dispatch(handleIncomingPhoneCall(callObject));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ElisaOC);
