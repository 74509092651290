import { connect } from 'react-redux';

import { activateIFrameTab, removeCaseIFrame } from '../../actions/caseIFrameActions';
import TabBar from '../../TabBar';

import type { State } from '../../types/initialState';
import type { MenuTab } from '../../types/MenuTab';
import type { TabBarMethod } from '../../types/TabBar';

interface OwnProps {
  caseTitle: string;
  frameTitle: string;
  taskId: number;
  activeTab: 'case' | 'iframe';
}

interface IFrameTab extends MenuTab {
  id: 'case' | 'iframe';
}

export default connect(
  (state: State, ownProps: OwnProps) => {
    const tabs: IFrameTab[] = [
      {
        id: 'case',
        title: ownProps.caseTitle,
        hasError: false,
        searchCriteria: [],
        accordionIndex: 0,
        activeTab: ownProps.activeTab === 'case'
      },
      {
        id: 'iframe',
        title: ownProps.frameTitle,
        hasError: false,
        searchCriteria: [],
        accordionIndex: 1,
        activeTab: ownProps.activeTab === 'iframe'
      }
    ];

    return {
      user: state.usersList.usersList.find((user) => user.UID === state.userData.UID),
      tabs: tabs,
      personalData: state.userData,
      ticketTypes: state.ticketTypes,
      tickets: state.detailedTickets,
      hideCreateNew: true
    };
  },
  (dispatch, ownProps: OwnProps) => {
    return {
      method: 'tickets' as TabBarMethod,
      onTabClose: () => {
        dispatch(removeCaseIFrame(ownProps.taskId));
      },
      onActivateTab: (id: IFrameTab['id']) => {
        dispatch(activateIFrameTab({ id: ownProps.taskId, activeTab: id }));
      },
      refreshMainView: () => {
        /**/
      }
    };
  }
)(TabBar);
