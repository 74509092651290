import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import iziToast from 'izitoast';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Form, Modal } from 'semantic-ui-react';

import type { Field } from '@eeedo/types';
import type { FC } from 'react';
import type { ConnectedProps } from 'react-redux';
import type { DropdownProps } from 'semantic-ui-react';

import EntityNewField from './EntityNewField';
import EntityApi from 'src/api/EntityApi';
import Info from 'src/Components/Case/Info/Info';
import Button from 'src/Components/generic/Button/Button';
import Dropdown from 'src/Components/generic/Dropdown/Dropdown';
import { getEntityFieldsByEntityType, getEntityOptions } from 'src/Utilities/ticketTypes';

import type { State } from 'src/types/initialState';

interface EntityCreationModalProps extends ConnectedProps<typeof connector> {
  onSuccess(): void;
}

const EntityCreationModal: FC<EntityCreationModalProps> = ({ ticketTypes, onSuccess }) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [selectedEntityType, setSelectedEntityType] = useState('');
  const [body, setBody] = useState({});
  const [fields, setFields] = useState<Field[]>([]);
  const [values, setValues] = useState({});

  useEffect(() => {
    setFields(getEntityFieldsByEntityType(ticketTypes, selectedEntityType).filter((field) => field.value !== '_id'));
  }, [selectedEntityType, ticketTypes]);

  const onEntityTypeChange = useCallback<NonNullable<DropdownProps['onChange']>>((_event, { value }) => {
    setSelectedEntityType(value as string);
  }, []);
  const onOpen = useCallback(() => {
    setOpen(true);
  }, []);
  const reset = useCallback(() => {
    setSelectedEntityType('');
    setBody({});
    setValues({});
  }, []);
  const onClose = useCallback(() => {
    reset();
    setOpen(false);
  }, []);
  const onInfoChange = useCallback(
    (field: Field, value: any) => {
      setBody({ ...body, [field.value as string]: value });
    },
    [body]
  );
  const onAddNewField = useCallback(
    (field: Field) => {
      setFields([...fields, field]);
    },
    [fields]
  );

  const entityOptions = useMemo(() => getEntityOptions(ticketTypes), [ticketTypes]);

  const createEntity = useCallback(
    () =>
      EntityApi.createEntity({
        entityType: selectedEntityType,
        ...body
      })
        .then(() => {
          setOpen(false);
          onSuccess();
        })
        .catch((error) => {
          console.error(error);
          iziToast.error({ message: t('entityViewer.create_entity_failed'), timeout: 3000, position: 'bottomRight' });
        }),
    [selectedEntityType, body]
  );

  const disableSaveButton = Number(entityOptions?.length) > 1 && !selectedEntityType;

  return (
    <Modal
      trigger={
        <Button
          type="primary"
          iconLeft={<FontAwesomeIcon icon={faPlus} />}
          content={t('entityViewer.create_entity_new_customer')}
        />
      }
      onClose={onClose}
      onOpen={onOpen}
      open={open}
      size="small"
      closeIcon
    >
      <Modal.Header>{t('entityViewer.create_entity_header')}</Modal.Header>
      <Modal.Content style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <Form>
          <Form.Group widths={'equal'}>
            <Form.Field>
              <label>{t('entityViewer.create_entity_type')}</label>
              <Dropdown
                selection
                clearable
                search
                options={entityOptions}
                placeholder={t('DROPDOWN_CHOOSE')}
                value={selectedEntityType}
                onChange={onEntityTypeChange}
              />
            </Form.Field>
          </Form.Group>
        </Form>
        <Info fields={fields} values={values} onChange={onInfoChange} />
        <EntityNewField disabled={disableSaveButton} onChange={onAddNewField} />
      </Modal.Content>
      <Modal.Actions style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button type="primary" disabled={disableSaveButton} onClick={createEntity}>
          {t('GENERAL_SAVE')}
        </Button>
        <Button type="error" onClick={reset}>
          {t('GENERAL_RESET')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

const connector = connect((state: State) => ({
  ticketTypes: state.ticketTypes
}));

export default connector(EntityCreationModal);
