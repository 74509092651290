import React from 'react';

import type { ISalesforceSettingsData } from '@eeedo/types';
import type { FC } from 'react';

import SalesforceCaseForm from './SalesforceCaseForm';
import SalesforceEmailForm from './SalesforceEmailForm';
import SalesforceLeadForm from './SalesforceLeadForm';
import SalesforceOpportunityForm from './SalesforceOpportunityForm';
import SalesforcePhoneForm from './SalesforcePhoneForm';
import SalesforceTaskForm from './SalesforceTaskForm';

import type { IGeneralSalesforceData, SalesforceFormPayload, SalesforceObjectType } from 'src/types/Salesforce';

interface SalesforceFormFieldProps {
  payload: SalesforceFormPayload;
  users: IGeneralSalesforceData[];
  contacts: IGeneralSalesforceData[];
  accounts: IGeneralSalesforceData[];
  validationErrors: Record<string, any>;
  salesforceSettings: ISalesforceSettingsData;

  handleSelectChange(property: string, value: string): void;
  handleSetState(fields: Partial<SalesforceFormPayload>): void;
  setSearchableSelectFields(type: SalesforceObjectType, stringToSearch: string, allowFullList?: boolean): void;
}

const types: Partial<SalesforceObjectType>[] = ['Task', 'Lead', 'Opportunity', 'Email', 'Call'];

const SalesforceFormField: FC<SalesforceFormFieldProps> = ({
  payload,
  users,
  accounts,
  contacts,
  validationErrors,
  salesforceSettings,

  handleSetState,
  handleSelectChange,
  setSearchableSelectFields
}) => {
  return (
    <>
      {payload.type === 'Task' && (
        <SalesforceTaskForm
          validationErrors={validationErrors}
          salesforceSettings={salesforceSettings}
          payload={payload}
          contacts={contacts}
          users={users}
          handleSetState={handleSetState}
          handleSelectChange={handleSelectChange}
          setSearchableSelectFields={setSearchableSelectFields}
        />
      )}

      {payload.type === 'Lead' && (
        <SalesforceLeadForm
          validationErrors={validationErrors}
          salesforceSettings={salesforceSettings}
          payload={payload}
          users={users}
          handleSetState={handleSetState}
          handleSelectChange={handleSelectChange}
          setSearchableSelectFields={setSearchableSelectFields}
        />
      )}

      {payload.type === 'Opportunity' && (
        <SalesforceOpportunityForm
          validationErrors={validationErrors}
          salesforceSettings={salesforceSettings}
          payload={payload}
          accounts={accounts}
          users={users}
          handleSetState={handleSetState}
          handleSelectChange={handleSelectChange}
          setSearchableSelectFields={setSearchableSelectFields}
        />
      )}

      {payload.type === 'Email' && (
        <SalesforceEmailForm validationErrors={validationErrors} payload={payload} handleSetState={handleSetState} />
      )}

      {payload.type === 'Call' && (
        <SalesforcePhoneForm validationErrors={validationErrors} payload={payload} handleSetState={handleSetState} />
      )}

      {!types.includes(payload.type!) && (
        <SalesforceCaseForm
          payload={payload}
          validationErrors={validationErrors}
          salesforceSettings={salesforceSettings}
          handleSetState={handleSetState}
          handleSelectChange={handleSelectChange}
          accounts={accounts}
          contacts={contacts}
          setSearchableSelectFields={setSearchableSelectFields}
        />
      )}
    </>
  );
};

export default React.memo(SalesforceFormField);
