import { faReply, faReplyAll, faShare } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../generic/Button/Button';
import styles from './CommentWidgetButtons.module.css';

export interface CommentWidgetButtonsProps {
  buttonsType?: 'basic' | 'primary';

  onReplyClick: () => void;
  onReplyAllClick?: () => void;
  onReplyForwardClick?: () => void;
}

export const CommentWidgetButtons = ({
  buttonsType,
  onReplyClick,
  onReplyAllClick,
  onReplyForwardClick
}: CommentWidgetButtonsProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.commentWidgetButtonsContainer}>
      <Button
        iconLeft={<FontAwesomeIcon icon={faReply} />}
        content={t('COMMENT_MAIL_REPLY')}
        type={buttonsType === 'primary' ? 'primary' : 'normal'}
        onClick={onReplyClick}
      />

      {onReplyAllClick && (
        <Button
          iconLeft={<FontAwesomeIcon icon={faReplyAll} />}
          content={t('COMMENT_MAIL_REPLY_ALL')}
          type={buttonsType === 'primary' ? 'primary' : 'normal'}
          onClick={onReplyAllClick}
        />
      )}

      {onReplyForwardClick && (
        <Button
          iconLeft={<FontAwesomeIcon icon={faShare} />}
          content={t('COMMENT_MAIL_FORWARD')}
          type={buttonsType === 'primary' ? 'primary' : 'normal'}
          onClick={onReplyForwardClick}
        />
      )}
    </div>
  );
};
