import { createSelector } from '@reduxjs/toolkit';
import sortBy from 'lodash/sortBy';

import type { Widget } from '@eeedo/types';

import { TemplateType } from 'src/types/Template';
import { Roles } from 'src/types/User';

import type { State } from 'src/types/initialState';
import type { Template, TemplateContentJson } from 'src/types/Template';
import type { Entity } from 'src/types/Ticket';

export const selectActiveTicket = createSelector(
  (state: State) => state.detailedTickets,
  (state: State) => state.activeTicketTab,
  (detailedTickets, acitveTicketTab) => detailedTickets.find((ticket) => ticket.id === acitveTicketTab)
);

export const selectActiveTicketsEntities = createSelector(selectActiveTicket, (ticket) => ticket?.entities);

export const selectSortedEntities = createSelector(selectActiveTicket, (ticket) =>
  sortBy(ticket?.entities, ['_type', '_id']).reduce(
    (entityTypesMap, entity) => ({
      ...entityTypesMap,
      [entity._type]: [...(entityTypesMap[entity._type] ?? []), entity]
    }),
    {} as Record<string, Entity[]>
  )
);

export const selectCurrentUser = createSelector(
  (state: State) => state.userData,
  (state: State) => state.usersList,
  (userData, usersList) => usersList.usersList.find((user) => user.UID === userData.UID)
);

export const selectIsCurrentUserAdmin = createSelector(selectCurrentUser, (currentUser) =>
  currentUser?.role.id ? Roles.isAdmin(currentUser.role.id) : false
);

export const selectActiveTicketType = createSelector(
  (state: State) => state.detailedTickets,
  (state: State) => state.activeTicketTab,
  (state: State) => state.ticketTypes,
  (detailedTickets, activeTicketTab, ticketTypes) => {
    const activeTicket = detailedTickets.find((ticket) => activeTicketTab === ticket.id);
    const taskTypeName = activeTicket?.taskType;
    return ticketTypes.find((tt) => tt.name === taskTypeName);
  }
);

export const selectRescheduleWidgetOptions = createSelector(
  selectActiveTicketType,
  (ticketType) =>
    (ticketType?.widgetOrder.find((widget) => (widget as Widget).name === 'RescheduleCallback') as Widget)?.options
      ?.values
);

export const selectContentTemplates = createSelector(
  (state: State) => state.templates.templates,
  (allTemplates) =>
    allTemplates.filter((template) => template.type === TemplateType.CONTENT) as unknown as (Omit<
      Template,
      'template'
    > & { template: TemplateContentJson })[]
);

export const selectActiveContentTemplates = createSelector(selectContentTemplates, (allTemplates) =>
  allTemplates.filter((template) => template.active)
);

export const selectAllowedContentTemplates = createSelector(
  (state: State) => state.userData.ticketTypes,
  (state: State) => state.ticketTypes,
  selectActiveContentTemplates,
  (userTicketTypes, ticketTypes, activeContentTemplates) => {
    return activeContentTemplates.filter(({ template }) => {
      const templateTicketType = ticketTypes.find((ticketType) => ticketType.name === template.ticketType);

      // normally means ticket type not allowed
      if (!templateTicketType) {
        return false;
      }

      return userTicketTypes.find((ticketTypeId) => {
        return ticketTypeId === templateTicketType.id;
      });
    });
  }
);

export const selectActiveMainTab = createSelector(
  (state: State) => state.ticketListTabs,
  (state: State) => state.ticketListActiveTabs.main,
  (tabs, main) => tabs[main]
);
