import React from 'react';
import { Input } from 'semantic-ui-react';

import type { FC } from 'react';
import type { InputOnChangeData, LabelProps, SemanticShorthandItem } from 'semantic-ui-react';

import { parseTicketNumber } from 'src/Utilities/parseUtils';

interface IProps {
  label?: SemanticShorthandItem<LabelProps>;
  placeholder: string;
  value: number | null;
  style?: React.CSSProperties;
  onChange: (value: number | null) => void;
  onKeyDown?: (event: React.KeyboardEvent) => void;
}

const TicketNumberInput: FC<IProps> = ({ placeholder, label, onChange, value, ...props }) => {
  const handleChange = (_e: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    onChange(Number(data.value));
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    const val = event.clipboardData.getData('text');
    onChange(parseTicketNumber(val));
  };

  return (
    <Input
      fluid
      label={label}
      labelPosition="right"
      min={0}
      placeholder={placeholder}
      size="mini"
      type="number"
      value={value || ''}
      onChange={handleChange}
      onPaste={handlePaste}
      data-test-id="ticketNumberInput"
      {...props}
    />
  );
};

export default TicketNumberInput;
