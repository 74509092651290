import React from 'react';

import ErrorBoundary from 'src/ErrorBoundary';
import MenuDropdown from 'src/MenuDropdown';
import ProfileDropdown from 'src/ProfileDropdown';

export const LeftSideMenuDropdown = () => (
  <ErrorBoundary>
    <MenuDropdown />
  </ErrorBoundary>
);

export const RightSideProfileDropdown = () => (
  <ErrorBoundary>
    <ProfileDropdown />
  </ErrorBoundary>
);
